/*
@File: abaris Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - About Area CSS
** - Shop Section CSS
** - Video Section CSS
** - Fun Facts Section CSS
** - Summary Section CSS
** - Services Section CSS
** - Cultivation Section CSS
** - Services Section CSS
** - Cultivation Section CSS
** - Fisherman Section CSS
** - Cowman Section CSS
** - Testimonials Section CSS
** - Blog Section CSS
** - Partner Section CSS
** - Farm Area CS
** - Cow Section CSS
** - Faq Section CSS
** - Subscribe Area CSS
** - Page Title Area CSS
** - Team Section CSS
** - Gallery Area CSS
** - Login In CSS
** - Sign Up CSS
** - 404 Error Area CSS
** - Contact Section CSS
** - Services Details CSS
** - Sidebar Widget Area CSS
** - Blog Details Area CSS
** - Pagination Area CSS
** - Footer Section CSS
** - Copyright Area CSS

*/
/*================================================
Default CSS
=================================================*/

body {
  padding: 0;
  margin: 0;
  font-family: "Rubik", sans-serif !important;
  /* font-family: 'Noto Sans', sans-serif !important; */
  font-size: 16px;
}

.preloaderCount {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffffff73;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 2;
}

.p-30 {
  padding: 30px 0;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #333333;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  text-decoration: none !important;
  outline: 0 !important;
  display: inline-block;
}

ul {
  list-style: none;
}

a:hover {
  color: #209e2e;
  text-decoration: none;
}

p {
  line-height: 1.8;
  color: #7a7e9a;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 14px;
}

p:last-child {
  margin-bottom: 0;
}

button {
  outline: 0 !important;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333333;
  font-weight: bold;
}

.loaderIcon svg {
  font-size: 30px;
  animation: loaderIcon 2s linear infinite;
}

@keyframes loaderIcon {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.default-btn {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: #ffffff;
  text-transform: capitalize;
  background-color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  font-weight: 400;
  font-size: 16px;
  padding: 12px 30px;
}

.logoutBtn {
  background-color: transparent;
  color: #fff;
  border: 0;
  font-size: 14px;
}

.btn.btn-primary.loadMore {
  background: #fb641b;
  display: inline-block;
  border-color: #fb641b;
  box-shadow: 0px 15px 10px -15px #a9a9a9;
}

.default-btn span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: #333333;
  -webkit-transition: width 0.6s ease-in-out, height 0.6s ease-in-out;
  transition: width 0.6s ease-in-out, height 0.6s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  border-radius: 5px;
}

.default-btn:hover {
  color: #ffffff;
}

.default-btn:hover span {
  width: 225%;
  height: 562.5px;
}

.default-btn i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.optional-btn {
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: #fff;
  text-transform: uppercase;
  background-color: #e89829;
  cursor: pointer;
  transition: 0.6s;
  font-size: 14px;
  padding: 6px 24px;
  font-weight: 600;
  border: 1px solid #454545;
}

.optional-btn i {
  margin-right: 2px;
  font-size: 18px;
}

.optional-btn:hover,
.optional-btn:focus {
  color: #ffffff !important;
  background-color: #000;
}

.optional-btn i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.section-title {
  text-align: center;
  margin-bottom: 50px;
}

.section-title span {
  font-size: 15px;
  color: #209e2e;
  text-transform: uppercase;
  font-weight: 400;
}

.section-title h3 {
  font-size: 38px;
  margin: 10px 0 16px 0;
}

.section-title p {
  max-width: 605px;
  margin: auto;
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #ffffff;
  top: 0;
  left: 0;
  z-index: 99999;
}

.preloader .lds-ripple {
  position: relative;
  width: 64px;
  height: 64px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.preloader .lds-ripple div {
  position: absolute;
  border: 4px solid #209e2e;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.preloader .lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

/*================================================
Navbar Area CSS
=================================================*/
.abaris-responsive-nav {
  display: none;
}

.abaris-nav {
  background-color: transparent;
}

.abaris-nav .navbar {
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 5px;
  padding-right: 25px;
  padding-top: 0;
  padding-left: 25px;
  padding-bottom: 0;
}

.abaris-nav .navbar .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand-md .navbar-nav {
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}

div#navbarSupportedContent {
  position: relative;
}

.abaris-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.abaris-nav .navbar .navbar-nav {
  margin-right: auto;
  margin-left: auto;
}

.abaris-nav .navbar .navbar-nav .nav-item {

  padding: 0;
}

.abaris-nav .navbar .navbar-nav .nav-item a {
  font-size: 13px;
  color: #333333;
  text-transform: uppercase;
  padding: 6px 0;
  margin: 0 15px;
  font-weight: bold;

}


.abaris-nav .navbar .navbar-nav .nav-item a:hover,
.abaris-nav .navbar .navbar-nav .nav-item a:focus,
.abaris-nav .navbar .navbar-nav .nav-item a.active {
  color: #ffb85a;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}


/* .abaris-nav .navbar .navbar-nav .nav-item a:hover::before, .abaris-nav .navbar .navbar-nav .nav-item a:focus::before, .abaris-nav .navbar .navbar-nav .nav-item a.active::before {
  content: "";
  position: absolute;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-color: #209e2e;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 30px;
  bottom: 20px;
} */
.abaris-nav .navbar .navbar-nav .nav-item a i {
  font-size: 16px;
}

.abaris-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}

.abaris-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}

.abaris-nav .navbar .navbar-nav .nav-item:hover a,
.abaris-nav .navbar .navbar-nav .nav-item.active a {
  color: #fcb248;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: absolute;
  border: none;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: capitalize;
  padding: 6px 0;
  margin: 0;
  position: relative;
  color: #333333;
  font-size: 15.5px;
  font-weight: 600;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #e89829;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover::before,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus::before,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active::before {
  display: none;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 220px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.abaris-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
  margin-top: 0;
}

.abaris-nav .navbar .others-options {
  margin-left: 0;
}

.abaris-nav .navbar .others-options .option-item {
  color: #333333;
  display: inline-block;
  position: relative;
  line-height: 1;
}

.abaris-nav .navbar .others-options .option-item .search-btn {
  display: block;
  color: #ffffff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .others-options .option-item .search-btn:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.abaris-nav .navbar .others-options .option-item .search-btn i {
  font-size: 16px;
}

.abaris-nav .navbar .others-options .option-item .close-btn {
  display: none;
  color: #ffffff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .others-options .option-item .close-btn.active {
  display: block;
}

.abaris-nav .navbar .others-options .option-item .close-btn:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.abaris-nav .navbar .others-options .option-item .close-btn i {
  font-size: 16px;
}

.abaris-nav .navbar .others-options .cart-btn {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: 1px;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .others-options .cart-btn i {
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .others-options .cart-btn a {
  color: #b7b7b7;
  position: relative;
}

.abaris-nav .navbar .others-options .cart-btn a span {
  position: absolute;
  right: -5px;
  bottom: 12px;
  width: 12px;
  height: 12px;
  text-align: center;
  line-height: 12px;
  border-radius: 50%;
  background: #209e2e;
  color: #ffffff;
  font-size: 10px;
  font-weight: 400;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .others-options .cart-btn:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.abaris-nav .navbar .others-options .cart-btn:hover a:hover i {
  color: #ffffff;
}

.abaris-nav .navbar .others-options .cart-btn:hover a span {
  background: #ffffff;
  color: #209e2e;
}

.abaris-nav .navbar .others-options .burger-menu {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: 1px;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .others-options .burger-menu:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.abaris-nav .navbar .others-options .burger-menu i::before {
  line-height: normal;
  font-size: 16px;
}

.navbar-area {
  position: absolute;
  left: 0;
  top: 35px;
  width: 100%;
  height: auto;
  z-index: 999;
  background-color: transparent;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.navbar-area.is-sticky .abaris-nav .navbar {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  padding-left: 0;
  padding-right: 0;
}

.navbar-area.navbar-style-two {
  border-bottom: 1px solid #5d5649;
  top: 0;
  padding-left: 40px;
  padding-right: 40px;
}

.navbar-area.navbar-style-two .abaris-nav {
  background-color: transparent;
}

.navbar-area.navbar-style-two .abaris-nav .navbar {
  background-color: transparent;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav::before {
  content: "";
  position: absolute;
  top: 0;
  left: -70px;
  height: 100%;
  width: 1px;
  background-color: #5d5649;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav::after {
  content: "";
  position: absolute;
  top: 0;
  right: -65px;
  height: 100%;
  width: 1px;
  background-color: #5d5649;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item a {
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item a.active {
  color: #ffb85a;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item:hover a,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item.active a {
  color: #ffb85a;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options {
  margin-left: 0;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item {
  color: #333333;
  display: inline-block;
  position: relative;
  line-height: 1;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .search-btn {
  display: block;
  color: #ffffff;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .search-btn:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .search-btn i {
  font-size: 16px;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .close-btn {
  display: none;
  color: #ffffff;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .close-btn.active {
  display: block;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .close-btn:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .close-btn i {
  font-size: 16px;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .cart-btn {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: 1px;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .cart-btn a {
  color: #b7b7b7;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .cart-btn a:hover {
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .cart-btn:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .cart-btn:hover a:hover i {
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .cart-btn i {
  font-size: 16px;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .burger-menu {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  margin-left: 10px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 1px;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .burger-menu:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .burger-menu i::before {
  line-height: normal;
  font-size: 16px;
}

.navbar-area.navbar-style-two.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two {
  border-bottom: none;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav {
  position: relative;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav::before {
  display: none;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav::after {
  display: none;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav .nav-item {
  position: relative;
  padding: 0;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav .nav-item a {
  color: #333333;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav .nav-item a:hover,
.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav .nav-item a:focus,
.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav .nav-item a.active {
  color: #209e2e;
}

.navbar-area-three {
  position: relative;
  background-color: transparent;
}

.navbar-area-three.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.navbar-area-three.is-sticky .abaris-nav .navbar {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  padding-left: 0;
  padding-right: 0;
}

.navbar-area-three .abaris-nav .navbar {
  background-color: #ffffff;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.search-overlay {
  display: none;
}

.search-overlay.search-popup {
  position: absolute;
  top: 100%;
  width: 300px;
  right: 0;
  background: #ffffff;
  z-index: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.search-overlay.search-popup .search-form {
  position: relative;
}

.search-overlay.search-popup .search-form .search-input {
  display: block;
  width: 100%;
  height: 50px;
  line-height: initial;
  border: 1px solid #eeeeee;
  color: #333333;
  outline: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  padding-top: 4px;
  padding-left: 10px;
}

.search-overlay.search-popup .search-form .search-input:focus {
  border-color: #209e2e;
}

.search-overlay.search-popup .search-form .search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: transparent;
  border: none;
  width: 50px;
  outline: 0;
  color: #7a7e9a;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  padding: 0;
}

.search-overlay.search-popup .search-form .search-button:hover,
.search-overlay.search-popup .search-form .search-button:focus {
  color: #209e2e;
}

.sidebar-modal {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  overflow: hidden;
}

.sidebar-modal .sidebar-modal-inner {
  position: absolute;
  right: -100%;
  top: 0;
  width: 30%;
  overflow-y: scroll;
  height: 100%;
  background-color: #ffffff;
  -webkit-transition: .7s;
  transition: .7s;
  z-index: 1;
  padding-top: 80px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.sidebar-modal .sidebar-modal-inner .close-btn {
  display: inline-block;
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 20px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #333333;
  opacity: .66;
  cursor: pointer;
}

.sidebar-modal .sidebar-modal-inner .close-btn:hover {
  opacity: 1;
}

.sidebar-modal .sidebar-about-area {
  margin-bottom: 40px;
}

.sidebar-modal .sidebar-about-area .title h2 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
}

.sidebar-modal .sidebar-about-area .title p {
  margin-top: 15px;
  font-size: 15px;
}

.sidebar-modal .sidebar-instagram-feed {
  margin-bottom: 40px;
}

.sidebar-modal .sidebar-instagram-feed h2 {
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 700;
}

.sidebar-modal .sidebar-instagram-feed ul {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 0;
  margin-top: -10px;
}

.sidebar-modal .sidebar-instagram-feed ul li {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}

.sidebar-modal .sidebar-instagram-feed ul li a {
  display: block;
  position: relative;
}

.sidebar-modal .sidebar-instagram-feed ul li a::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  opacity: 0;
  visibility: hidden;
}

.sidebar-modal .sidebar-instagram-feed ul li a:hover::before {
  opacity: .50;
  visibility: visible;
}

.sidebar-modal .sidebar-contact-area .contact-info {
  text-align: center;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a {
  display: inline-block;
  color: #209e2e;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:hover {
  color: #333333;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:not(:first-child) {
  color: #333333;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:not(:first-child):hover {
  color: #209e2e;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 span {
  display: block;
  color: #7a7e9a;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 20px;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li {
  display: inline-block;
  margin: 0 1px;
  padding-left: 0;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a {
  width: 35px;
  height: 35px;
  line-height: 34px;
  border: 1px solid #dadada;
  border-radius: 50%;
  color: #aba5a5;
  display: block;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a i {
  font-size: 14px;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a:hover {
  color: #ffffff;
  border-color: #209e2e;
  background-color: #209e2e;
}

.sidebar-modal.active {
  opacity: 1;
  visibility: visible;
}

.sidebar-modal.active .sidebar-modal-inner {
  right: 0;
}


/* Brands CSS Start */
.brandsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewMoreBrands a {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  background: transparent;
  border-color: #454545;
  color: #454545;
  border-radius: 0;
  padding: 6px 12px;
}

/* Brands CSS End */


/* Privacy Policy CSS Start */
.privacyWrapper {
  background-color: #f7f7f7;
  padding: 40px 0;
}

.privacyItem {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.privacyIcon svg {
  font-size: 30px;
  color: #454545;
}

.privacyItem p a {
  color: #454545;
}

.privacyItem p {
  font-size: 18px;
  text-transform: capitalize;
  color: #454545;
}

/* Privacy Policy CSS End */

/* Seller Category CSS Start */
.sellerCategorySec {
  padding: 30px 0;
}

.bestSellerCard {
  padding: 20px;
  border: 1px solid #ededed;
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.bestSellerText a {
  display: inline-block;
  margin-top: 16px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  background: #ededed;
  border-color: #ededed;
  color: #454545;
  border-radius: 0;
  padding: 8px 14px;
}

.categoriesBody {
  display: flex;
  align-items: center;
  column-gap: 20px;
  flex-wrap: wrap;
  row-gap: 15px;
}

.categoryImg img {
  width: 100%;
}

.categoryItem {
  flex: 30%;
}

.categoryItem.card {
  border-radius: 0;
  border: 0;
}

.categoryText.card-body {
  padding: 10px;
  padding-left: 0;
}

.categoryText.card-body h6 {
  margin: 0;
}

/* Seller Category CSS End */

/* Product Item CSS Start */
.dialogueImg {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;
}

.dialogueImgInfo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.thumbView img {
  cursor: pointer;
}

.dialogueImgInfo img {
  box-shadow: 0px 15px 10px -15px #a9a9a9;
}

.dialogueImgHeader {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.dialogueImgHeader .title {
  margin: 0;
}

.dialogueImgHeader .cancel {
  font-size: 22px;
  cursor: pointer;
  background: #fb641b;
  width: 30px;
  height: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid
}

.quickView {
  position: absolute;
  top: 10px;
  right: -40px;
  z-index: 1;
  transition: all 0.3s;
}

.featuredImg:hover .quickView {
  right: 10px;
}


.quickView ul {
  margin: 0;
  padding: 0;
}

.quickView ul li {
  width: 40px;
  height: 40px;
  background: #fff;
  background-color: #e89829 !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 5px;
  cursor: pointer;
}

.quickView ul li a,
.quickView ul li button {
  color: #454545;
  line-height: 14px;
  display: inline-block;
  padding: 10px;
}

.quickView ul li button {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

/* Product Item CSS End */


/*your custom css goes here*/


.btnFocus:link,
.btnFocus:visited {
  text-transform: uppercase;
  text-decoration: none;
  /* padding: 10px 15px; */
  width: 45px;
  height: 45px;
  display: inline-block;
  border-radius: 100px;
  transition: all .2s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnFocus:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(183, 42, 42, 0.2);
}

.btnFocus:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btnFocus-white {
  background-color: #fff;
  color: #777;
}

.btnFocus::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s;
}

.btnFocus:hover {
  background-color: cadetblue !important;
}

.btnFocus-white::after {
  background-color: aqua;
}

.btnFocus:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
  background-color: aqua;
}

.btnFocus-animated {
  animation: moveInBottom 5s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.featuredImg {
  position: relative;
}

.hoverEffect {
  position: absolute;
  top: -18px;
  right: -230px;
  display: none;
}

.hoverEffect2 {
  display: none;
  position: absolute;
  top: 37px;
  right: -230px;
}

.btnFocus-white:hover {
  background-color: rgb(89, 211, 255);
}

.featuredFigure:hover .hoverEffect {
  display: block;
}

.featuredFigure:hover .hoverEffect2 {
  display: block;
}

.modal-content {
  width: 750px;
}





.picZoomer {
  position: relative;
  /*margin-left: 40px;
    padding: 15px;*/
}

.picZoomer-pic-wp {
  position: relative;
  overflow: hidden;
  text-align: center;
}

.picZoomer-pic-wp:hover .picZoomer-cursor {
  display: block;
}

.picZoomer-zoom-pic {
  position: absolute;
  top: 0;
  left: 0;
}


.picZoomer-zoom-wp {
  display: none;
  position: absolute;
  z-index: 999;
  overflow: hidden;
  border: 1px solid #eee;
  height: 460px;
  margin-top: -19px;
}

.picZoomer-cursor {
  display: none;
  cursor: crosshair;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #eee;
  background-color: rgba(0, 0, 0, .1);
}

.picZoomCursor-ico {
  width: 23px;
  height: 23px;
  position: absolute;
  top: 40px;
  left: 40px;
  /* background: url(images/zoom-ico.png) left top no-repeat; */
}

.my_img {
  vertical-align: middle;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
}

.piclist li {
  display: inline-block;
  width: 90px;
  height: 114px;
  border: 1px solid #eee;
}

.piclist li img {
  width: 97%;
  height: auto;
}

/* custom style */
.picZoomer-pic-wp,
.picZoomer-zoom-wp {
  border: 1px solid #eee;
}



.section-bg {
  background-color: #fff1e0;
}


.row-sm .col-md-6 {
  padding-left: 5px;
  padding-right: 5px;
}

/*===pic-Zoom===*/
._boxzoom .zoom-thumb {
  width: 90px;
  display: inline-block;
  vertical-align: top;
  margin-top: 0px;
}

._boxzoom .zoom-thumb ul.piclist {
  padding-left: 0px;
  top: 0px;
}

._boxzoom ._product-images {
  width: 80%;
  display: inline-block;
}

._boxzoom ._product-images .picZoomer {
  width: 100%;
}

._boxzoom ._product-images .picZoomer .picZoomer-pic-wp img {
  left: 0px;
}

._boxzoom ._product-images .picZoomer img.my_img {
  width: 100%;
}

.piclist li img {
  height: 100px;
  object-fit: cover;
}

/*======products-details=====*/
._product-detail-content {
  background: #fff;
  padding: 0 15px;
}

._product-detail-content p._p-name {
  color: black;
  font-size: 20px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 12px;
  text-transform: capitalize;
}

.p-list span {
  margin-right: 15px;
}

.p-list span.price {
  font-size: 25px;
  color: #318234;
}

._p-qty>span {
  color: black;
  margin-right: 15px;
  font-weight: 500;
}

._p-qty .value-button {
  display: inline-flex;
  border: 0px solid #ddd;
  margin: 0px;
  width: 30px;
  height: 39px;
  justify-content: center;
  align-items: center;
  background: #fd7f34;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
}

._p-qty .value-button {

  height: 39px;
  font-size: 20px;
  font-weight: bold;
}

._p-qty input#number {
  text-align: center;
  /* border: none;
  border-top: 1px solid #fd7f34;
  border-bottom: 1px solid #fd7f34; */
  margin: 0px;
  width: 50px;
  height: 35px;
  font-size: 14px;
  box-sizing: border-box;
}

._p-add-cart {
  margin-left: 0px;
  margin-bottom: 15px;
}

.p-list {
  margin-bottom: 10px;
}

._p-features>span {
  display: block;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

._p-add-cart .buy-btnFocus {
  background-color: #fd7f34;
  color: #fff;
}

._p-add-cart .btnFocus {
  text-transform: capitalize;
  padding: 6px 20px;
  /* width: 200px; */
  border-radius: 52px;
}

._p-add-cart .btnFocus {
  margin: 0px 8px;
}

/*=========Recent-post==========*/
.title_bx h3.title {
  font-size: 22px;
  text-transform: capitalize;
  position: relative;
  color: #fd7f34;
  font-weight: 700;
  line-height: 1.2em;
}

.title_bx h3.title:before {
  content: "";
  height: 2px;
  width: 20%;
  position: absolute;
  left: 0px;
  z-index: 1;
  top: 40px;
  background-color: #fd7f34;
}

.title_bx h3.title:after {
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 40px;
  background-color: #ffc107;
}

.common_wd .owl-nav .owl-prev,
.common_wd .owl-nav .owl-next {
  background-color: #fd7f34 !important;
  display: block;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 0px !important;
}

.owl-nav .owl-next {
  right: -10px;
}

.owl-nav .owl-prev,
.owl-nav .owl-next {
  top: 50%;
  position: absolute;
}

.common_wd .owl-nav .owl-prev i,
.common_wd .owl-nav .owl-next i {
  color: #fff;
  font-size: 14px !important;
  position: relative;
  top: -1px;
}

.common_wd .owl-nav {
  position: absolute;
  top: -21%;
  right: 4px;
  width: 65px;
}

.owl-nav .owl-prev i,
.owl-nav .owl-next i {
  left: 0px;
}

._p-qty .decrease_ {
  position: relative;
  right: 0px;
  top: 0;
}

._p-qty .increase_ {
  position: relative;
  top: 0;
  left: 0px;
}

/*========box========*/
.sq_box {
  padding-bottom: 5px;
  border-bottom: solid 2px #fd7f34;
  background-color: #fff;
  text-align: center;
  padding: 15px 10px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.item .sq_box span.wishlist {
  right: 5px !important;
}

.sq_box span.wishlist {
  position: absolute;
  top: 10px;
  right: 20px;
}

.sq_box span {
  font-size: 14px;
  font-weight: 600;
  margin: 0px 10px;
}

.sq_box span.wishlist i {
  color: #adb5bd;
  font-size: 20px;
}

.sq_box h4 {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  color: #343a40;
  margin-top: 10px;
  margin-bottom: 10px !important;
}

.sq_box .price-box {
  margin-bottom: 15px !important;
}

.sq_box .btnFocus {
  border-radius: 50px;
  padding: 5px 13px;
  font-size: 15px;
  color: #fff;
  background-color: #fd7f34;
  font-weight: 600;
}

.sq_box .price-box span.price {
  text-decoration: line-through;
  color: #6c757d;
}

.sq_box span {
  font-size: 14px;
  font-weight: 600;
  margin: 0px 10px;
}

.sq_box .price-box span.offer-price {
  color: #28a745;
}

.sq_box img {
  object-fit: cover;
  height: 150px !important;
  margin-top: 20px;
}

.sq_box span.wishlist i:hover {
  color: #fd7f34;
}

/* Products Filter Item  CSS Start */
/* .productsFilterItem .formCheck:nth-child(n+7) {
  display: none;
}

.productsFilterItem.active .formCheck:nth-child(n+7) {
  display: block;
} */

.viewBtnn {
  display: flex;
  justify-content: end;
}

.btn.btn-primary.filterMore {
  text-transform: capitalize;
  border: 0;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 600;
  color: #2a2a2a;
  display: inline-block;
  margin: 5px 0;
  text-decoration: underline;
}

/* Products Filter Item  CSS End */

/* Product Detail Content Start  */
.shareProductSec.borderTop {
  padding-bottom: 0;
}

.shareProduct ul {
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.shareProduct ul li a {
  font-size: 18px;
}

.shareProduct ul .whatsapp {
  color: #1bd741;
}

.shareProduct ul .facebook {
  color: #3c579e;
}

.shareProduct ul .twitter {
  color: #1cb8ec;
}

.shareProduct ul .instagram {
  color: #ec0a68;
}

.pickupList ol li a {
  color: #252525;
  font-size: 15px;
}

/* Product Detail Content End  */


/* Product Content Start  */
.product-add-to-cart.addToCart.productCartBtn {
  justify-content: start;
}

.modal-dialog.maxWidthDialog {
  max-width: 100%;
}

.modal-content.maxWidthContent {
  width: 1300px;
  margin: auto;
  border: 0;
  border-radius: 4px;
}

.addQuantity._p-qty {
  display: flex;
  align-items: center;
}

.IncItem {
  border: 1px solid #fd7f34;
}

.IncItem input#number {
  border: 0;
}

.product-add-to-cart.addToCart .default-btn {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.row.changeGrid.listView .featuredInfo {
  display: flex;
  column-gap: 15px;
}

.row.changeGrid.listView .col-lg-3 {
  width: 100%;
}

.productDesc {
  display: none;
}

.row.changeGrid.listView .featuredContent {
  width: 100%;
  text-align: left;
}

.row.changeGrid.listView .featuredImg {
  width: 280px;
}

.row.changeGrid.listView .featuredImg img {
  min-height: 254px;
}

.row.changeGrid.listView .featuredContent .rateDigit {
  justify-content: start;
}

.row.changeGrid.listView .productDesc {
  display: block;
}

.quantityValue {
  display: inline-block;
  width: 40px;
  text-align: center;
}

/* Product Content End  */
/* Review  Start  */
.reviewContentSec {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
}

/* Review Slider End  */
/* Search Box Start  */
.callText {
  text-align: right;
}

.searchIconsItem .wishListBtn {
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: 25px;
}

.searchIconsItem .countWish {
  position: relative;
}

.searchIconsItem .countWish .wishCircle {
  align-items: center;
  background-color: #fb641b;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  height: 8px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 8px;
}

/* Search Box End  */

/* Cart Start  */
.cartBody {
  padding: 30px 0 20px;
}

.cartTable {
  padding: 15px;
  border: 1px solid #ededed;
}

.cartTable tr td {
  vertical-align: middle;
}

.productImageInfo {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.productImage img {
  width: 100px;
  border-radius: 8px;
}

.productImageInfo figure {
  margin: 0;
  box-shadow: 0 8px 6px -6px #555;
}

.productImageInfo h5 a {
  color: #333333;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}

.table th {
  color: #484848;
}



.cartTotals {
  background: #f7f7f7;
  padding: 20px;
  border: 1px dashed #eeeeee;
}

.cartTitle {
  font-weight: bold;
  margin-bottom: 15px;
}

.subTotal,
.totalAmount {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subTotal h6 {
  margin: 0;
}

.shippingForm {
  padding: 10px 0;
}

.shippingForm .btn {
  background: #fb641b;
  border-radius: 0;
  border-color: #fb641b;
  font-size: 14px;
  text-transform: uppercase;
}

.proceed {
  margin-top: 15px;
}

.proceed .btn {
  text-transform: uppercase;
  background: #222529;
  width: 100%;
  border-color: #222529;
  border-radius: 0;
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: center;
  padding: 15px 0;
}

.cartTitle {
  font-weight: bold;
}

.totalAmount h6 {
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
}

.totalAmount p,
.subTotal p {
  color: #222529;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
}

.subTotal p {
  font-size: 15px;
}

.shippingRadio label {
  font-size: 14px;
}

.shippingForm select,
.shippingForm input {
  border-radius: 0;
  font-size: 14px;
}

.cartDiscountInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.cart-discount button,
.updateBtn button {
  background: #222529;
  border-color: #222529;
  border-radius: 0;
  font-size: 14px;
  text-transform: uppercase;
}

.updateBtn button {
  background: #fb641b;
  border-color: #fb641b;
}

.shippingForm .title {
  margin: 10px 0 10px;
}

.quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.quantity .minus,
.quantity .plus {
  color: #222529;
  background: #f7f7f7;
  padding: 0 9px;
  font-size: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  border: 1px solid #d4d4d4;
  border-radius: 100%;
}

.quantity .minus svg,
.quantity .plus svg {
  color: #222529;
}

table.cartTablePage .quantity {
  justify-content: start;
}

/* Cart End  */

/* Checkout Start  */
.billingDetails label {
  font-size: 14px;
  margin-bottom: 5px;
}

.billingDetails input,
.billingDetails textarea,
.billingDetails select {
  border-radius: 0;
  font-size: 14px;
}

.billingDetails input::placeholder,
.billingDetails textarea::placeholder {
  font-size: 14px;
}

.loginInfo {
  padding: 12px;
  border: 1px solid #dbdbdb;
  margin-bottom: 15px;
  background-color: #f7f7f7;
}

.loginItem p,
.coupon p {
  color: #000000;
}

.loginItem p a,
.coupon p a {
  color: #455fbd;
}

.billingDetails h5 {
  font-size: 20px;
  font-weight: 600;
}

.orderInfo {
  border: 1px solid #dbdbdb;
  background: #f7f7f7;
  padding: 20px;
  position: sticky;
  top: 110px;
}

.paymentMethods p svg {
  color: #0d6efd;
  margin-right: 5px;
}

/* Checkout End  */

/* Registration Start  */
.registrationDetail {
  padding: 10px 0;
  background: url("../img/kaira/logo/abc.jpg") no-repeat left / cover;
  height: 100vh;
  position: relative;
}

.registrationInfo {
  display: flex;
  align-items: stretch;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  box-shadow: 0px 15px 10px -15px #a9a9a9;
  width: 50%;

}

.registerFormField input {
  border-radius: 0;
}

.registerFormField input:focus {
  box-shadow: none;
  border-color: #2874f0;
}

.registerForm,
.registerContent {
  flex: 1;
  padding: 40px 33px;
}

.registerContent {
  background: linear-gradient(to bottom, #13171e, #ff1e00);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.contentHeader h3 {
  color: #fff;
  font-size: 30px;
  margin-bottom: 15px;
}

.contentHeader p {
  color: #dbdbdb;
  font-size: 16px;
  line-height: 26px;
}

.registerForm {
  background: #fff;
  border: 1px solid #ededed;
  border-left: 0;
  border-bottom: 0;
}



.label.form-check-label.agreeCheck {
  font-size: 13px;
  color: #333;
}

button.btn.btn-primary.createAccount {
  width: 100%;
  background: #fb641b;
  border-color: #fb641b;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 15px;
  padding: 10px 0;
  letter-spacing: 1px;
}

.joinWith {
  text-align: center;
  margin: 17px 0;
  position: relative;
}

.joinWith:before {
  content: "";
  height: 1px;
  background: #d5d5d5;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
}

.joinWith span {
  display: inline-block;
  background: #fff;
  position: relative;
  padding: 0 10px;
  text-transform: uppercase;
  font-size: 13px;
  color: #747474;
}

.connectWith ul {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  column-gap: 10px;
}

.connectWith ul li a {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d2ecd5;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  border-radius: 30px;
  margin-right: 5px;
}

.connectWith ul li a:hover {
  transform: translateY(-6px);
}

.connectWith ul li a.facebook {
  background-color: #3c579e;
}

.connectWith ul li a.twitter {
  background-color: #1cb8ec;
}

.connectWith ul li a.google {
  background-color: #e62833;
}

.alreadyAccount {
  text-align: center;
}

.alreadyAccount {
  margin-top: 20px;
}

.alreadyAccount p {
  margin: 0;
}

.alreadyAccount a {
  text-decoration: underline !important;
}

/* Registration End  */

/* Login Start  */
.form-check.mb-3.forgotInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

label.form-check-label.agreeCheck,
.forgotText a {
  font-size: 14px;
  color: #363636;
}

.forgotText a {
  text-decoration: underline !important;
}

/* Login End  */

/* Reset Start  */
.alreadyAccount.reset {
  display: flex;
  justify-content: center;
  column-gap: 6px;
}

/* Reset End  */

/* Track Code Start  */
.trackDetail {
  padding: 30px 0 50px;
  background-color: #ededed;
  border-top: 1px solid #d5d5d5;
}

.trackInfo {
  padding: 30px;
  background: #fff;
  box-shadow: 0px 15px 10px -15px;
  border: 1px dashed #d5d5d5;
}

/* Track Code End  */


/* Seller Start  */

/* Seller End  */

/* Seller Header Start */
.sellerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sellerMenu {
  display: flex;
  align-items: center;
}

.sellerMenu a img {
  width: 85px;
}

.sellerMenu ul {
  margin: 0;
  display: flex;
  align-items: center;
}

.sellerMenu ul li a {
  padding: 14px 8px;
  line-height: 20px;
  color: rgb(96, 112, 128);
  font-size: 15px;
}

.sellerHeaderSec {
  padding: 10px 15px;
}

.sellerRegistration ul {
  display: flex;
  margin: 0;
  padding: 0;
  column-gap: 20px;
  align-items: center;
}

/* Seller Header End */

/* Seller Banner Start */
.sellerBannerSec {
  background: url("../img/sellerBanner.svg") no-repeat center / cover;
  padding: 50px 0;
}

.sellerBannerContent {
  width: calc(100% - 70%);
}

.sellerBannerContent input {
  font-size: 14px;
  border-radius: 0;
  height: 50px;
  border: 0;
}

.sellerBannerContent .sellerBtn {
  background-color: #fb641b;
  border-color: #fb641b;
  border-radius: 0;
  text-transform: uppercase;
}

.startSellingBtn button {
  text-transform: uppercase;
  border-radius: 0;
  font-size: 14px;
  box-shadow: 0px 15px 10px -15px;
}

/* Seller Banner End */

/* Seller Testimonial Start */
.tesTimonialUserInfo {
  column-gap: 15px;
  display: flex;
  justify-content: flex-start;
}

.tesTimonialUserInfo .tesTimonialUserImg {
  max-width: 120px;
  width: 100%;

}

.tesTimonialUserImg img {
  border-radius: 4px;
}

.tesTimonialUserInfo .tesTimonialUserText {
  width: 100%;
}

.tesTimonialUserText p {
  margin: 0;
  line-height: 23px;
}

/* Seller Testimonial End */


/* About Seller Start */
.aboutSellerSec {
  border-top: 1px solid #c9c9c9;
}

.aboutSell {
  display: flex;
  justify-content: center;
}

.aboutSell li button {
  color: #333333;
  font-weight: 500;
}

.aboutSell li button:hover {
  background-color: #25782e;
  color: #fff;
  border-color: #25782e;
  border-radius: 0;
}

.aboutSell li button.nav-link.active {
  border-radius: 0;
  color: #333;
}

.sellerTabInfo {
  padding-top: 20px;
}

.sellerTabInfo span {
  background: #25782e;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  font-size: 18px;
  border-radius: 50%;
  color: #fff;
  box-shadow: 0px 15px 10px -15px #a9a9a9;
}

.stripedSec {
  background: aliceblue;
}

.stripedBackground {
  justify-content: center;
  padding: 20px 0;
  display: flex;
  align-items: center;
  column-gap: 25px;
}

.stripedBackground img {
  width: 100px;
}

.stripedBackground a {
  color: #fb641b;
  text-transform: uppercase;
  font-weight: 600;
}

/* About Seller End */

/* Create Shop Start */
.createSec {
  background: #f3f3f3;
}

.registerFormInfo {
  background: #fff;
  padding: 20px;
  box-shadow: 0px 15px 10px -15px #a9a9a9;
}

.personalInfo h5 {
  margin-bottom: 15px;
}

.personalInfo input,
.personalInfo textarea {
  border-radius: 0;
}

.personalInfo label {
  font-size: 13px;
  margin-bottom: 5px;
}

.personalInfo input::placeholder,
.personalInfo textarea::placeholder {
  font-size: 14px;
}

button.btn.btn-primary.registerShop {
  background-color: #25782e;
  border-color: #25782e;
  border-radius: 0;
}

.card-body.moreToolBody svg {
  fill: #fb641b;
  font-size: 23px;
  margin-bottom: 10px;
}

/* Create Shop End */

/* Popular Category Start */
.PopularCategoriesSec.p-30 {
  background: #f9f9f9;
}

.PopularCategoryInfo ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  gap: 10px;
}

.PopularCategoryInfo ul li a {
  background: #dddbdb;
  padding: 4px 10px;
  border-radius: 4px;
  color: #333;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

/* Popular Category End */


/* Sell Footer Category Start */

.sellFooter {
  background: #124a2f;
}

.sellFooterInfo ul {
  padding: 0;
  margin: 0;
}

.sellFooterInfo h5 {
  color: #fff;
}

.sellFooterInfo ul li a {
  font-size: 14px;
  color: #dfdfdf;
}

/* Sell Footer Category End */

/* Terms Start */
.termsSec {
  padding: 30px 0;
}

/* Terms End */


/* About Page Start */
.aboutBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.aboutBannerInfo {
  padding: 30px 0;
}

.aboutBannerInfo h2 {
  font-weight: 600;
  margin-bottom: 25px;
}

.aboutBannerInfo a {
  background-color: #fb641b;
  border-color: #fb641b;
  border-radius: 0;
  text-transform: uppercase;
}

.featuresSection {
  background-color: #f4f4f4;
}

.feature-box-content {
  padding: 30px;
}

.countContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.count-wrapper h4 {
  color: #124a2f;
}

.count-wrapper h4 span {
  color: #333;
  font-size: 13px;
  padding-left: 4px;
}

/* About Page End */

/* Contact Page Start */
.connectSec {
  background: #f4f4f4;
  padding: 40px 0;
}

.feature-box-content svg {
  font-size: 30px;
  fill: #124a2f;
}

.feature-box-content h4 {
  margin: 15px 0 5px;
}

/* Contact Page End */


/* Blog Page Start */
.blogSec {
  padding: 50px 0;
  background-color: #f4f4f4;
}

.blogItemInfo {
  margin-bottom: 30px;
}

.blogItemFigure {
  position: relative;
  overflow: hidden;
}

.blogItemFigure img {
  transition: .4s ease-in-out;
}

.blogItemInfo:hover .blogItemFigure img {
  transform: scale(1.1);
}

.postDate {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #fb641b;
  padding: 15px 13px;
  text-align: center;
}

.postDate span {
  display: block;
  color: #fff;
}

.postDate .date {
  font-weight: 500;
  line-height: 23px;
  font-size: 28px;
  margin-bottom: 4px;
}

.postDate .month {
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
}

.blogItemContent {
  box-shadow: 0px 15px 10px -15px #a9a9a9;
  background-color: #fff;
}

.blogItemContent .author ul {
  padding: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 15px;
}

.blogItemContent .author ul li a {
  color: #7a7e9a;
  align-items: center;
  display: flex;
  gap: 10px;
  font-size: 14px;
  transition: .4s ease-in-out;
}

.blogItemContent .author ul li a svg {
  fill: #fb641b;
  font-size: 16px;
}

.blogItemContent .title {
  padding: 0 20px 20px;
}

.blogItemContent .title h4 a {
  text-transform: capitalize;
  color: #212529;
}

.blogItemContent .readMore {
  padding: 20px;
  border-top: 1px dashed #d5d5d5;
}

.blogItemContent .readMore a {
  color: #808287;
  text-transform: uppercase;
  font-size: 14px;
  align-items: center;
  display: flex;
  column-gap: 10px;
}

.blogItemContent .readMore a svg {
  color: #fb641b;
  font-size: 16px;
}

.blogAsideSec {
  position: sticky;
  top: 110px;
}

.asideTitle {
  font-size: 20px;
  text-transform: capitalize;
}

.blogCategory,
.recentPosts,
.asideTags {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid #e5e5e5;
}

.blogCategory ul {
  padding: 0;
  margin: 0;
}

.blogCategory ul li a {
  color: #333;
  padding: 8px 0;
  display: block;
  font-size: 15px;
  transition: .4s ease-in-out;
}

.blogCategory ul li a:hover {
  padding-left: 3px;
}

.blogCategory ul li a svg {
  margin-right: 5px;
}

.recentItemInfo .recentImg {
  width: 30%;
}

.recentPosts .recentItemInfo {
  display: flex;
  column-gap: 20px;
  margin-bottom: 17px;
}

.asideTags ul {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.asideTags ul li a {
  background: #124a2f;
  padding: 2px 10px;
  border-radius: 4px;
  color: #ffffff;
  text-transform: capitalize;
  font-size: 14px;
}

.recentContent h6 {
  margin-bottom: 5px;
}

.recentContent h6 a {
  color: #333333;
}

.blogFormSec {
  margin-top: 20px;
  padding: 30px;
  background: #ededed;
}

.blogFormInfo label {
  font-size: 14px;
  margin-bottom: 5px;
}

.blogFormInfo input,
.blogFormInfo textarea {
  border-radius: 0;
}

.blogFormInfo input {
  height: 40px;
}

.blogFormInfo input::placeholder,
.blogFormInfo textarea::placeholder {
  font-size: 14px;
}

.btn.secondaryBtn {
  border-radius: 0;
  background: #fb641b;
  border-color: #fb641b;
  text-transform: uppercase;
  color: #fff;
}

.comment-option h4 {
  color: #19191a;
  margin-bottom: 35px;
}

.single-comment-item {
  margin-bottom: 30px;
}

.single-comment-item .sc-author {
  float: left;
  margin-right: 28px;
}

.single-comment-item .sc-author img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.single-comment-item.first-comment .sc-text {
  padding-left: 22px;
}

.single-comment-item .sc-text {
  display: table;
  position: relative;
}

.single-comment-item .sc-text span {
  font-size: 12px;
  color: #124a2f;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.single-comment-item .sc-text h5 {
  font-size: 20px;
  color: #19191a;
  margin-top: 4px;
  margin-bottom: 8px;
}

.blog-details-text .comment-option .single-comment-item .sc-text p {
  color: #707079;
  margin-bottom: 18px;
}

.single-comment-item .sc-text a {
  display: inline-block;
  color: #19191a;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 24px;
  border: 1px solid #F9EEE3;
  border-radius: 50px;
  transition: all 0.3s;
  margin-right: 10px;
}

.single-comment-item .sc-text a:hover {
  color: #fff;
  background-color: #124a2f;
  border-color: #124a2f;
}

.single-comment-item.first-comment .sc-text:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: #ebebeb;
  content: "";
}

/* Blog Page End */

/* Photo Page Start */
.pageTitle {
  margin-bottom: 30px;
}

/* Photo Page End */

/* Video Page Start */
.videoFrame iframe {
  margin-bottom: 20px;
}

/* Video Page End */


/* Product Page Start */
.buyNowInfo {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: center;
  margin-top: 5px;
}

.buyNowInfo .addCart {
  background-color: #fff;
  border: 0;
  color: #fb641b;
}

.buyNowInfo .addCart:hover {
  background-color: #000;
  border-color: #fff;
}

.buyNowInfo .buyNow {
  background-color: #e89829;
  border-color: #e89829;
  color: #fff;
}

.buyNowInfo .buyNow:hover {
  background-color: #000;
  border-color: #ffff;
}

.buyNowInfo .addCart,
.buyNowInfo .buyNow {
  font-size: 13px;
  display: flex;
  column-gap: 4px;
  align-items: center;
}

/* Product Page End */


/* FAQ Page Start */
.accordion-item.faqAccordionInfo {
  border-radius: 0;
  margin-bottom: 15px;
}

.accordion-button.faqAccordionBtn.collapsed {
  border-top: 1px solid #dee2e6;
}

.accordion-button.faqAccordionBtn,
.accordion-button.faqAccordionBtn:not(.collapsed) {
  background: #fff;
  color: #333;
  border-radius: 0;
}

.accordion-button.faqAccordionBtn:focus,
.accordion-button.faqAccordionBtn:not(.collapsed) {
  background-color: #fff;
}

.accordion-button.faqAccordionBtn:focus {
  box-shadow: none;
}

.accordion-body.faqAccordionBody {
  border-top: 1px solid #dee2e6;
}

/* FAQ Page End */


/* Seller Page Start */
.sellerAbout {
  display: flex;
  column-gap: 15px;
  align-items: center;
  justify-content: center;
}

.brandContent h4 {
  font-size: 20px;
  margin: 0;
}

.sellerDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-top: 1px dashed #d3d3d3;
}

.sellerMenus ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.sellerMenus ul li a {
  padding: 15px 10px;
  border-bottom: 2px solid #ffffff;
  color: #333;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.sellerMenus ul li a.active {
  border-bottom: 2px solid #7979ff;
}

.sellerSocial ul {
  display: flex;
  padding: 0;
  margin: 0;
}

.sellerMenus {
  display: flex;
}

.sellerTopHeader {
  text-align: center;
  margin: 20px auto;
  width: 66%;
  position: relative;
}

.sellerTopHeader h2 {
  text-transform: uppercase;
  font-size: 25px;
}

.sellerSocial {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.searchSeller svg {
  color: #25782e;
  font-size: 25px;
  cursor: pointer;
  margin: 0 10px;
}

.searchSeller {
  display: flex;
  align-items: center;
  border: 1px solid #d3d3d3;
}

.searchSeller input {
  border: 0;
  border-radius: 0;
  background: #fff;
}

.searchSeller input:focus {
  box-shadow: none;
}

.searchSeller input:focus::placeholder {
  color: #25782e;
}

/* Seller Page End */

/* Testimonial Page Start */
.carousel .testimonial {
  color: #808080;
  position: relative;
  padding: 15px;
  background: #f1f1f1;
  border: 1px solid #efefef;
  border-radius: 3px;
  margin-bottom: 15px;
  margin-right: 15px;
}

.carousel .testimonial::after {
  content: "";
  width: 15px;
  height: 15px;
  display: block;
  background: #f1f1f1;
  border: 1px solid #efefef;
  border-width: 0 0 1px 1px;
  position: absolute;
  bottom: -8px;
  left: 46px;
  transform: rotateZ(-46deg);
}

.carousel .media {
  position: relative;
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.carousel .media img {
  width: 75px;
  height: 75px;
  display: block;
  border-radius: 50%;
}

.carousel .overview {
  padding: 3px 0 0 15px;
}

.carousel .overview b {
  text-transform: uppercase;
  color: #1abc9c;
}

.carousel .overview .details {
  padding: 5px 0 8px;
}

.carousel .overview .details h5 {
  font-size: 15px;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.sliderWrapper .slick-dots {
  position: static;
  margin-top: 30px;
}

.carousel .star-rating li {
  padding: 0 2px;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.list-inline-item {
  display: inline-block;
}

.carousel .star-rating i {
  font-size: 16px;
  color: #ffdc12;
}

.reward {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ededed;
  margin-top: 15px;
}

.reward .text {
  margin: 0;
  padding: 10px;
}

.reward .number {
  padding: 10px;
  background: #fb641b;
  color: #fff;
}

/* Testimonial Page End */

/* Trending Section Start */
.trendingProductSe {
  background: #f7f7f7;
}

/* Trending Section End */

/* Related Product Section Start */
.relatedProductSec {
  background: #f7f7f7;
}

.relatedProductSec .featuredInfo {
  margin-right: 15px;
}

/* Related Product Section End */


/* Quick View Modal Start*/
.modalViewSec {
  display: flex;
  column-gap: 20px;
}

.modalFigure {
  width: 40%;
}

.modalText {
  width: 60%;
}

.modalFigure {
  display: flex;
  column-gap: 20px;
}

.thumbnailSec {
  width: 25%;
}

.thumbnailSec ul {
  padding: 0;
  margin: 0;
}

.thumbnailSec ul li {
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  min-height: 80px;
  cursor: pointer;
  padding: 5px;
  display: flex;
}

.productSize {
  margin: 20px 0;
}

.productSize ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.productSize ul li a {
  padding: 10px 17px;
  background: #f7f7f7;
  color: #333;
  font-size: 15px;
  border-radius: 4px;
  border: 2px solid #f5f5f5;
}

.productSize ul li a label {
  display: block;
}

.productSize ul li a .offer {
  color: #fd7f34;
  display: inline-block;
  margin-left: 4px;
}

.interestPayment {
  padding: 9px 0;
}

.interestPayment a {
  font-size: 13px;
  font-weight: 500;
  text-decoration: underline !important;
  color: #5151ff !important;
}

.modalText {
  overflow: auto;
  height: 400px;
}

.saveImg {
  padding-top: 10px;
}

.saveImg {
  padding-top: 10px;
}

.countryList ul {
  padding: 0;
  margin: 0;
}

.countryList {
  padding: 15px 0;
}


.countryList ul li {
  display: flex;
  align-items: center;
  column-gap: 10px;
  line-height: 30px;
  font-size: 15px;
}

/* Quick View Modal End*/


/* Back to Top Start*/
.backTop {
  width: 40px;
  height: 40px;
  background: #ffa921;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  box-shadow: 0px 15px 10px -15px #a9a9a9;
  position: fixed;
  right: 27px;
  bottom: 20px;
  z-index: 111;
  transition: .4s ease-in-out;
  border-radius: 100%;
}

/* Back to Top End*/
.error-content img {
  width: 40%;
}

/* .todayDealsSec .featuredInfo {
  margin-right: 15px;
} */

.trustFigure img {
  width: 80%;
}

.lightboxSec.lightboxjs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  column-gap: 30px;
  row-gap: 30px;
}

.lightboxSec.lightboxjs img {
  max-width: 31.33%;
  margin: 0 !important;
  height: 204px;
  object-fit: cover;
}

.saveImg img {
  width: 60%;
}

.row.changeGrid.listView .featuredContent {
  position: relative;
}

.row.changeGrid.listView .buyNowInfo {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  flex-direction: column;
}

.row.changeGrid.listView .buyNowInfo .addCart {
  margin-bottom: 10px;
}

.modalText::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}

.modalText::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modalText::-webkit-scrollbar {
  width: 10px;
}

/* Loader Sec Start*/
.loaderSec {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11111;
  background-color: #fff;
}

/* Loader Sec End*/

/* FAQ Sec Start */
.faqCategory {
  display: flex;
  justify-content: center;
}

.faqCategory .nav.nav-tabs .nav-link.faqTab {
  display: flex;
  align-items: center;
  column-gap: 10px;
  border: 0;
  background: rgb(18 74 47 / 62%);
  border-radius: 0;
  color: #fff;
  padding: 10px 40px;
}

.faqCategory .nav.nav-tabs .nav-link.faqTab.active {
  background-color: #124a2f;
}

/* FAQ Sec End */
/* Product Item */

/* Brand Page Start */
.brandsItem.fullBrandsItem ul {
  justify-content: center;
  row-gap: 15px;
  flex-wrap: wrap;
}

.brandsItem.fullBrandsItem ul li {
  width: 23%;
}

.brandsItem.fullBrandsItem ul li a {
  background: #fdfdfd;
  box-shadow: none;
  justify-content: center;
  border: 1px solid #ededed;
}

/* Brand Page End */


/* Privacy Page End */
.privacyInfo h1,
.deliveryTitle h1,
.careersTitle h1 {
  font-size: 30px;
}

.privacyBody {
  margin-bottom: 20px;
}

/* Privacy Page End */

/* Recent Viewed Start */
.recentViewedSec {
  background-color: #fff;
  padding: 25px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 400px;
  z-index: 1111;
  transform: translateX(-400px);
  transition: .4s ease-in-out;
  box-shadow: rgb(17 17 26 / 5%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 0px 8px;
}

.recentViewedSec.active {
  transform: translateX(0px);
}

.btn.btn-primary.recentViewBtn {
  position: fixed;
  right: 0;
  top: 50%;
  transform: rotate(90deg) translateY(-50%);
  background: #25782e;
  border-radius: 0;
  border-color: #25782e;
  padding: 6px 19px;
  z-index: 111;
}

.recentViewHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recentViewHeader h4 {
  margin: 0;
}

.recentViewHeader button {
  background: #fb641b;
  border: 0;
  width: 35px;
  height: 35px;
  font-size: 27px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.recentViewItem {
  margin-top: 20px;
  background: #ededed;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  column-gap: 10px;
  margin-bottom: 20px;
}

.recentViewItem .figure {
  width: 100px;
}

.recentViewItem .text {
  padding-top: 15px;
}

.recentViewItem .text h5 {
  font-size: 18px;
  margin: 0;
}

.recentViewItem .text p {
  margin: 0;
}

.priceBottom .price {
  color: #252525;
}

.priceBottom button {
  background: #25782e;
  border: 0;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 4px;
}

/* Recent Viewed End */

/* Category Item Slider Start */

/* the slides */

.categoryItemSlider .slick-arrow.slick-prev,
.categoryItemSlider .slick-arrow.slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
  background: #fb641b;
  width: 35px;
  height: 35px;
  border-radius: 4px;
}

.dot {
  display: none !important;
}

.categoryItemSlider .slick-arrow.slick-prev {
  left: 0px;
}

.categoryItemSlider .slick-arrow.slick-next {
  right: 0px;
}

/* Category Item Slider End */

/* Network Page Start */
.networkSec {
  padding: 30px 0;
}

.serviceList {
  border-bottom: 1px solid #ebebeb;
  background-color: #ffffff;
}

.networkItem ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.networkItem ul li {
  width: 100%;
  background: #124a2f;
  padding: 20px;
  border-right: 1px solid #0c3a24;
  text-align: center;
}

.brandsSec {
  padding: 30px 0 50px;
  background: #f7f7f7;
}

.networkItem ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.networkNumber h2 {
  color: #fff;
  margin: 0;
  font-size: 26px;
}

.networkNumber p {
  text-transform: capitalize;
  color: aliceblue;
}

/* Network Page End */


/* Copyright Section Start */
.copyright-area .copyright-area-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Copyright Section End */

@media only screen and (max-width: 1120px) {
  .networkNumber h2 {
    font-size: 24px;
  }

  .modalViewSec {
    flex-wrap: wrap;
  }

  .modalFigure,
  .modalText {
    width: 100%;
  }

  .thumbnailSec {
    width: 15%;
  }

  .modal-content.maxWidthContent {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .networkNumber h2 {
    font-size: 26px;
  }

  .registrationInfo {
    width: 90%;
  }

  .abaris-responsive-nav {
    display: block;
  }

  .abaris-responsive-nav .abaris-responsive-menu {
    position: relative;
  }

  .abaris-responsive-nav .abaris-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px;
  }

  .abaris-responsive-nav .abaris-responsive-menu.mean-container .mean-nav ul li a.active {
    color: #209e2e;
  }

  .abaris-responsive-nav .abaris-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 14px;
  }

  .abaris-responsive-nav .abaris-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 336px;
    -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }

  .abaris-responsive-nav .mean-container a.meanmenu-reveal {
    color: #333333;
  }

  .abaris-responsive-nav .mean-container a.meanmenu-reveal span {
    background: #333333;
    position: relative;
    top: 8px;
    margin-top: -5.8px;
    border-radius: 5px;
  }

  .abaris-responsive-nav .mean-container .others-option {
    display: none;
  }

  .abaris-responsive-nav .logo {
    position: relative;
    width: 50%;
    z-index: 999;
  }

  .navbar-area {
    background-color: #ffffff;
    position: absolute;
    top: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .navbar-area.navbar-style-two {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-area.navbar-style-two.is-sticky {
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff !important;
  }

  /* .abaris-nav {
    display: none;
  } */

  .categoryItemSlider .slick-arrow.slick-next {
    right: 8px;
  }

  .categoryItemSlider .slick-arrow.slick-prev {
    left: 8px;
  }

  .networkItem ul {
    flex-wrap: wrap;
  }

  .networkItem ul li {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .networkItem ul li {
    border-bottom: 1px solid #0c3a24;
  }

  .menuList h4 {
    font-size: 16px;
  }

  .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
    font-size: 14px;
    padding: 3px 0;
  }
}

@media (max-width: 480px) {
  .copyright-area .copyright-area-content {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 950px;
}

.main-banner-content {
  max-width: 630px;
  margin-left: auto;
}

.main-banner-content h1 {
  font-size: 52px;
  color: #333333;
  margin: 0 0 16px 0;
}

.main-banner-content p {
  color: #7a7e9a;
  margin: 0 0 0 0;
}

.main-banner-content .banner-btn {
  margin-top: 35px;
}

.main-banner-content .banner-list {
  padding-left: 0;
  margin-bottom: 20px;
  position: relative;
}

.main-banner-content .banner-list li {
  list-style-type: none;
  display: inline-block;
  text-align: center;
  position: relative;
  border-right: 1px dotted #d0ebd3;
  padding: 0 20px;
}

.main-banner-content .banner-list li:first-child {
  padding-left: 0;
}

.main-banner-content .banner-list li:last-child {
  border-right: none;
}

.main-banner-content .banner-list i {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #eefbf2;
  color: #209e2e;
  border-radius: 30px;
  border: 2px dotted #d0eed6;
  font-weight: 500;
}

.main-banner-content .banner-list i::before {
  font-size: 25px;
}

.main-banner-content .banner-list sup {
  bottom: 0;
  position: absolute;
  right: 26px;
  background-color: #209e2e;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff;
}

.main-banner-content .banner-list span {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #209e2e;
  font-weight: 400;
  margin-top: 12px;
  line-height: 1;
}

.banner-image {
  text-align: right;
  margin-top: -10px;
  position: relative;
}

.banner-image .shape-area .creative-shape {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  left: -4%;
}

.banner-image .shape-area .creative-shape-two {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  left: -1%;
}

.shape-img1 {
  position: absolute;
  left: 40%;
  bottom: 10%;
  z-index: -1;
  -webkit-animation: moveLeftBounce 3s linear infinite;
  animation: moveLeftBounce 3s linear infinite;
}

.shape-img2 {
  position: absolute;
  left: 10px;
  top: 0;
  z-index: -1;
  -webkit-animation: moveScale 3s linear infinite;
  animation: moveScale 3s linear infinite;
}

.shape-img3 {
  position: absolute;
  left: 0;
  bottom: 15%;
  z-index: -1;
  -webkit-animation: moveBounce 5s linear infinite;
  animation: moveBounce 5s linear infinite;
}

.shape-img4 {
  position: absolute;
  left: 35%;
  right: auto;
  top: 14%;
  z-index: -1;
  -webkit-animation: moveLeftBounce 3s linear infinite;
  animation: moveLeftBounce 3s linear infinite;
}

.shape-img5 {
  position: absolute;
  left: 20%;
  right: auto;
  top: 40%;
  z-index: -1;
  -webkit-animation: animationFramesOne 10s infinite linear;
  animation: animationFramesOne 10s infinite linear;
}

@-webkit-keyframes rotateMe {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotateMe {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(-73px, 1px) rotate(-36deg);
    transform: translate(-73px, 1px) rotate(-36deg);
  }

  40% {
    -webkit-transform: translate(-141px, -72px) rotate(-72deg);
    transform: translate(-141px, -72px) rotate(-72deg);
  }

  60% {
    -webkit-transform: translate(-83px, -122px) rotate(-108deg);
    transform: translate(-83px, -122px) rotate(-108deg);
  }

  80% {
    -webkit-transform: translate(40px, -72px) rotate(-144deg);
    transform: translate(40px, -72px) rotate(-144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(-73px, 1px) rotate(-36deg);
    transform: translate(-73px, 1px) rotate(-36deg);
  }

  40% {
    -webkit-transform: translate(-141px, -72px) rotate(-72deg);
    transform: translate(-141px, -72px) rotate(-72deg);
  }

  60% {
    -webkit-transform: translate(-83px, -122px) rotate(-108deg);
    transform: translate(-83px, -122px) rotate(-108deg);
  }

  80% {
    -webkit-transform: translate(40px, -72px) rotate(-144deg);
    transform: translate(40px, -72px) rotate(-144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@-webkit-keyframes moveScale {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }

  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
}

@keyframes moveScale {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }

  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
}

@-webkit-keyframes moveLeftBounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes moveLeftBounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes moveBounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes moveBounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

/*================================================
Home Banner Two Area CSS
=================================================*/
.home-banner-two {
  position: relative;
  overflow: hidden;
}

.home-banner-two .home-slides {
  position: relative;
}

.home-banner-two .home-slides .home-item {
  position: relative;
  height: 910px;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  overflow: hidden;
}

.home-banner-two .home-slides .home-item::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #000000;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.7;
}

.home-banner-two .home-slides .home-item.item-bg1 {
  background-image: url(../../assets/img/home-two/bg-1.jpg);
}

.home-banner-two .home-slides .home-item.item-bg2 {
  background-image: url(../../assets/img/home-two/bg-2.jpg);
}

.home-banner-two .home-slides .home-item.item-bg3 {
  background-image: url(../../assets/img/home-two/bg-3.jpg);
}

.home-banner-two .home-slides .home-item .main-banner-content {
  max-width: 630px;
  margin-right: auto;
  margin-left: unset;
}

.home-banner-two .home-slides .home-item .main-banner-content h1 {
  font-size: 55px;
  color: #ffffff;
  margin: 0 0 16px 0;
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-two .home-slides .home-item .main-banner-content p {
  color: #ffffff;
  margin: 0 0 0 0;
  -webkit-animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-btn {
  margin-top: 35px;
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-btn .default-btn {
  border: none;
  padding: 14px 30px;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-btn .optional-btn {
  border: none;
  padding: 14px 30px;
  background-color: #ffffff;
  color: #209e2e;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-btn .optional-btn:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list {
  padding-left: 0;
  margin-bottom: 20px;
  position: relative;
  -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 700ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list li {
  list-style-type: none;
  display: inline-block;
  text-align: center;
  position: relative;
  border-right: 1px dashed #d0ebd3;
  padding: 0 20px;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list li:first-child {
  padding-left: 0;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list li:last-child {
  border-right: none;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list i {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #eefbf2;
  color: #209e2e;
  border-radius: 30px;
  border: 2px dotted #d0eed6;
  font-weight: 500;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list i::before {
  font-size: 25px;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list sup {
  bottom: 0;
  position: absolute;
  right: 26px;
  background-color: #209e2e;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list span {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  margin-top: 12px;
  line-height: 1;
}

.home-banner-two .home-slides .home-item .main-banner-content b {
  color: #209e2e;
}

.home-banner-two .home-slides.owl-theme .owl-dots {
  margin: 0 !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 80px;
  display: -ms-grid;
  display: grid;
}

.home-banner-two .home-slides.owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 20px;
  background-color: transparent;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 50%;
  border: 1px solid #ffffff;
  position: relative;
}

.home-banner-two .home-slides.owl-theme .owl-dots .owl-dot span::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  border-radius: 50%;
  margin: 4px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.home-banner-two .home-slides.owl-theme .owl-dots .owl-dot:hover span::before,
.home-banner-two .home-slides.owl-theme .owl-dots .owl-dot.active span::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.home-banner-two .banner-social-buttons {
  position: absolute;
  left: 30px;
  top: 60%;
  -webkit-transform: translateY(-60%);
  transform: translateY(-60%);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-transform: translate(-100%, -100%) rotate(90deg);
  transform: translate(-100%, -100%) rotate(90deg);
  z-index: 1;
}

.home-banner-two .banner-social-buttons ul {
  padding-left: 0;
  margin-bottom: 0;
}

.home-banner-two .banner-social-buttons ul li {
  color: #ffffff;
  list-style-type: none;
  display: inline-block;
  margin-right: 20px;
}

.home-banner-two .banner-social-buttons ul li:last-child {
  margin-right: 0;
}

.home-banner-two .banner-social-buttons ul a {
  color: #ffffff;
}

.home-banner-two .banner-social-buttons ul a:hover {
  color: #209e2e;
}

.home-banner-two .banner-social-buttons ul i {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-transform: translate(-50%, -50%) rotate(270deg);
  transform: translate(-50%, -50%) rotate(270deg);
}

.home-banner-two .creative-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}

.home-banner-two .shape-img1 {
  position: absolute;
  right: 30px;
  left: auto;
  bottom: 46px;
  z-index: 1;
  -webkit-animation: moveLeftBounce 3s linear infinite;
  animation: moveLeftBounce 3s linear infinite;
}

.home-banner-two .shape-img2 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  -webkit-animation: moveScale 3s linear infinite;
  animation: moveScale 3s linear infinite;
}

/*================================================
Home Banner Three Area CSS
=================================================*/
.home-banner-three {
  position: relative;
}

.home-banner-three .home-slides {
  position: relative;
}

.home-banner-three .home-slides .home-item {
  position: relative;
  height: 910px;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  overflow: hidden;
}

.home-banner-three .home-slides .home-item::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5;
}

.home-banner-three .home-slides .home-item.item-bg {
  background-image: url(../../assets/img/home-three/bg.png);
}

.home-banner-three .home-slides .home-item .main-banner-content {
  max-width: 630px;
  margin-left: auto;
  margin-right: 0;
}

.home-banner-three .home-slides .home-item .main-banner-content h1 {
  font-size: 55px;
  color: #333333;
  margin: 0 0 16px 0;
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-three .home-slides .home-item .main-banner-content p {
  color: #333333;
  margin: 0 0 0 0;
  -webkit-animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-btn {
  margin-top: 35px;
  -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 700ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-btn .default-btn {
  border: none;
  padding: 14px 30px;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-btn .optional-btn {
  border: none;
  padding: 14px 30px;
  background-color: #eafef1;
  color: #209e2e;
  border: 1px solid #209e2e;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-btn .optional-btn:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list {
  padding-left: 0;
  margin-bottom: 20px;
  position: relative;
  -webkit-animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list li {
  list-style-type: none;
  display: inline-block;
  text-align: center;
  position: relative;
  border-right: 1px dashed #d0ebd3;
  padding: 0 20px;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list li:first-child {
  padding-left: 0;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list li:last-child {
  border-right: none;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list i {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #eefbf2;
  color: #209e2e;
  border-radius: 30px;
  border: 2px dotted #d0eed6;
  font-weight: 500;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list i::before {
  font-size: 25px;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list sup {
  bottom: 0;
  position: absolute;
  right: 26px;
  background-color: #209e2e;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list span {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #209e2e;
  font-weight: 400;
  margin-top: 12px;
  line-height: 1;
}

.home-banner-three .home-slides .home-item .main-banner-content strong {
  color: #209e2e;
}

.home-banner-three .home-slides.owl-theme .owl-dots {
  margin: 0 !important;
  position: absolute;
  bottom: 10%;
  -webkit-transform: translateY(-10%);
  transform: translateY(-10%);
  right: 0;
  left: 0;
  display: -ms-grid;
  display: inline-block;
}

.home-banner-three .home-slides.owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 20px;
  background-color: transparent;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 50%;
  border: 1px solid #209e2e;
  position: relative;
}

.home-banner-three .home-slides.owl-theme .owl-dots .owl-dot span::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #209e2e;
  border-radius: 50%;
  margin: 4px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.home-banner-three .home-slides.owl-theme .owl-dots .owl-dot:hover span::before,
.home-banner-three .home-slides.owl-theme .owl-dots .owl-dot.active span::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.home-banner-three .creative-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}

/*================================================
About Section CSS
=================================================*/
.about-section {
  position: relative;
}

.about-section.bg-f4faf4 {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-color: #f4faf4;
}

.about-section.section-bottom {
  padding-bottom: 175px;
}

.about-title-area {
  margin-bottom: 60px;
}

.about-title-area .about-title {
  border-right: 1px solid #dfdddf;
  padding-right: 35px;
}

.about-title-area .about-title span {
  color: #209e2e;
  font-size: 15px;
  display: block;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.about-title-area .about-title h2 {
  margin-bottom: 0;
  font-size: 38px;
  font-weight: 700;
}

.about-title-area .about-text {
  padding-left: 35px;
}

.about-title-area .about-text p {
  margin-bottom: 0;
}

.about-title-area .about-text .read-more-btn {
  margin-top: 18px;
  display: inline-block;
  color: #209e2e;
  font-size: 16px;
  font-weight: 600;
}

.about-title-area .about-text .read-more-btn i {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  display: inline-block;
}

.about-title-area .about-text .read-more-btn:hover i {
  margin-left: 3px;
}

.about-slider .about-item .about-text {
  padding: 20px;
  background-color: #fdfdfd;
  border-left: 1px dashed #8fe2aa;
  border-right: 1px dashed #8fe2aa;
  border-bottom: 1px dashed #8fe2aa;
  border-radius: 0 0 5px 5px;
  line-height: 1;
}

.about-slider .about-item .about-text span {
  font-size: 15px;
  color: #209e2e;
  position: relative;
  padding-left: 18px;
}

.about-slider .about-item .about-text span::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #209e2e;
  left: 0;
  top: 4px;
  border-radius: 30px;
}

.about-slider .about-item .about-text h3 {
  font-size: 16px;
  margin: 10px 0 0 0;
  line-height: 22px;
}

.about-slider.owl-theme .owl-nav {
  opacity: 0;
  visibility: hidden;
  margin-top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.about-slider.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: -16px;
  top: 50%;
  height: 40px;
  width: 40px;
  line-height: 38px;
  background-color: #209e2e !important;
  border: 1px solid #209e2e !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff !important;
  font-size: 16px;
  border-radius: 50px;
}

.about-slider.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -16px;
}

.about-slider.owl-theme .owl-nav [class*=owl-]:hover {
  color: #209e2e !important;
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}

.about-slider.owl-theme .owl-dots {
  line-height: .01;
  margin-top: 30px;
  margin-bottom: 0;
}

.about-slider.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 0 4px;
  background-color: #333333;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 30px;
}

.about-slider.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #209e2e;
}

.about-slider.owl-theme .owl-dots .owl-dot.active span {
  background-color: #209e2e;
}

.about-slider.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.about-content-area .about-content {
  margin-top: 20px;
}

.about-content-area .about-content h3 {
  font-size: 25px;
  margin: 0 0 10px 0;
}

.about-content-area .about-content p {
  margin: 0 0 0 0;
}

.about-content-area .about-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: -10px;
  margin-right: -10px;
}

.about-content-area .about-list li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.about-content-area .about-list i {
  display: inline-block;
  height: 25px;
  width: 25px;
  line-height: 25px;
  background-color: #eafef1;
  color: #209e2e;
  border: 1px solid #209e2e;
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-right: 6px;
}

.about-shape-image {
  position: absolute;
  top: 220px;
  z-index: -1;
}

.default-shape .default-shape-one {
  position: absolute;
  bottom: 0;
}

.default-shape .default-shape-two {
  position: absolute;
  top: 0;
}

/*================================================
Harvesting Section CSS
=================================================*/
.harvesting-section {
  background-image: url(../../assets/img/harvesting/harvesting-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.harvesting-title {
  text-align: center;
  margin-bottom: 50px;
}

.harvesting-title span {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
  color: #ffffff;
}

.harvesting-title h3 {
  font-size: 38px;
  color: #ffffff;
  margin: 12px 0 0 0;
}

.harvesting-item {
  background-color: #ffffff;
  padding: 40px;
  text-align: center;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 5px;
  margin-top: -30px;
  top: 30px;
}

.harvesting-item .harvesting-image {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  text-align: center;
  position: relative;
}

.harvesting-item .harvesting-image img {
  display: inline-block;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.harvesting-item .harvesting-image img:last-child {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  opacity: 0;
}

.harvesting-item .harvesting-content h3 {
  font-size: 26px;
  color: #209e2e;
  margin: 20px 0 0 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.harvesting-item::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  border-radius: 5px;
  background-color: #209e2e;
  z-index: -1;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.harvesting-item:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.harvesting-item:hover .icon i {
  color: #ffffff;
}

.harvesting-item:hover h3 {
  color: #ffffff;
}

.harvesting-item:hover i {
  color: #ffffff;
}

.harvesting-item:hover::before {
  height: 100%;
}

.harvesting-item:hover .harvesting-image img {
  opacity: 0;
}

.harvesting-item:hover .harvesting-image img:last-child {
  opacity: 1;
}

/*================================================
Shop Section CSS
=================================================*/
.shop-section.section-top {
  padding-top: 130px;
}

.tab .tabs_item {
  display: none;
}

.tab .tabs_item:first-child {
  display: block;
}

.shop-list-tab .tabs {
  padding-left: 0;
  margin-bottom: 50px;
  text-align: center;
}

.shop-list-tab .tabs li {
  text-align: center;
  display: inline-block;
  list-style: none;
  padding: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-right: 1px dashed #209e2e;
}

.shop-list-tab .tabs li:first-child {
  padding-left: 0;
}

.shop-list-tab .tabs li:last-child {
  border: none;
  padding-right: 0;
}

.shop-list-tab .tabs li a {
  display: block;
  border-radius: 2px;
  color: #8d8c8c;
  font-size: 18px;
  font-weight: 400;
}

.shop-list-tab .tabs li a span {
  display: block;
  margin-top: 0;
}

.shop-list-tab .tabs li.current a,
.shop-list-tab .tabs li:hover a {
  color: #209e2e;
}

.shop-list-tab .tabs.tabs-two {
  border: 1px dashed #209e2e;
  max-width: 440px;
  margin: 0px auto 40px;
  padding: 16px 0;
  border-radius: 5px;
}

.shop-list-tab .tab_content .tabs_item .shop-item {
  padding: 30px 50px;
  border-radius: 5px;
  border: 1px dashed #209e2e;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  margin-bottom: 30px;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-list {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 40px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: relative;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-list::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 75px;
  background-color: #eeeded;
  left: 0;
  top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: -1;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-list::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 75px;
  background-color: #eeeded;
  right: 0;
  top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: -1;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-list li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-list i {
  color: #f4a708;
  font-size: 15px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-content h3 {
  font-size: 20px;
  color: #616161;
  margin: 12px 0 10px 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-content span {
  font-size: 18px;
  color: #209e2e;
  font-weight: bold;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-content .shop-btn {
  margin-top: 15px;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-content .shop-btn .shop-btn-one {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  padding: 12px 30px;
  background-color: #eafef1;
  color: #209e2e;
  border: 1px solid #209e2e;
  border-radius: 50px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-content .shop-btn .shop-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 0;
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-content .shop-btn .shop-btn-one:hover {
  background-color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  border-radius: 5px;
  background-color: #209e2e;
  z-index: -1;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item:hover {
  border: 1px dashed #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item:hover i {
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item:hover h3 {
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item:hover span {
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item:hover i {
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item:hover::before {
  height: 55%;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two {
  padding: 30px 50px;
  border-radius: 5px;
  border: 1px dashed #209e2e;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  background-color: #d2ecd5;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-content {
  margin-top: 30px;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-content h3 {
  font-size: 20px;
  color: #616161;
  margin: 0 0 10px 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-content span {
  font-size: 18px;
  color: #209e2e;
  font-weight: bold;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-list {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: relative;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-list::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 75px;
  background-color: #209e2e !important;
  left: 0;
  top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-list::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 75px;
  background-color: #209e2e !important;
  right: 0;
  top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-list li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-list i {
  color: #f4a708;
  font-size: 15px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon li {
  display: inline-block;
  list-style-type: none;
  margin-right: 5px;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon li:last-child {
  margin-right: 0;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon i {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  background-color: #eafef1;
  border: 1px solid #209e2e;
  border-radius: 30px;
  font-size: 14px;
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon i:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon .big-icon i {
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background-color: #eafef1;
  border: 1px solid #209e2e;
  border-radius: 30px;
  font-size: 14px;
  color: #209e2e;
  font-weight: bold;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon .big-icon i:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two:hover {
  border: 1px dashed #209e2e;
  background-color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two:hover i {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two:hover h3 {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two:hover span {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two:hover i {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three {
  padding: 30px 50px;
  border-radius: 5px;
  border: 1px dashed #209e2e;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  background-color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-content {
  margin-top: 30px;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-content h3 {
  font-size: 20px;
  color: #616161;
  margin: 0 0 10px 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-content span {
  font-size: 18px;
  color: #209e2e;
  font-weight: bold;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-list {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: relative;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-list::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 75px;
  background-color: #209e2e !important;
  left: 0;
  top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-list::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 75px;
  background-color: #209e2e !important;
  right: 0;
  top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-list li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-list i {
  color: #f4a708;
  font-size: 15px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon li {
  display: inline-block;
  list-style-type: none;
  margin-right: 5px;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon li:last-child {
  margin-right: 0;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon i {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  background-color: #eafef1;
  border: 1px solid #209e2e;
  border-radius: 30px;
  font-size: 14px;
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon i:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon .big-icon i {
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background-color: #eafef1;
  border: 1px solid #209e2e;
  border-radius: 30px;
  font-size: 14px;
  color: #209e2e;
  font-weight: bold;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon .big-icon i:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three:hover {
  border: 1px dashed #209e2e;
  background-color: #d2ecd5;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three:hover i {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three:hover h3 {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three:hover span {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three:hover i {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .product-btn {
  text-align: center;
  margin-top: 40px;
}

.shop-list-tab .tab_content .tabs_item .product-btn .product-btn-one {
  display: inline-block;
  border: none;
  padding: 12px 30px;
  background-color: #209e2e;
  color: #ffffff;
  border: 1px solid #209e2e;
  border-radius: 30px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .product-btn .product-btn-one:hover {
  background-color: #ffffff;
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .product-btn .product-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

/*================================================
Video Section CSS
=================================================*/
.video-content {
  text-align: center;
  background-image: url(../../assets/img/video-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

.video-content .video-btn {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 100px;
  background-color: #ffffff;
  border-radius: 50%;
  color: #209e2e;
  position: relative;
  z-index: 1;
}

.video-content .video-btn i {
  font-size: 30px;
  position: relative;
  top: 2px;
  left: 4px;
}

.video-content .video-btn::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

.video-content .video-btn::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}

.video-content .video-btn:hover,
.video-content .video-btn .video-content .video-btn:focus {
  background-color: #209e2e;
  color: #ffffff;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

/*================================================
Fun Facts Section CSS
=================================================*/
.fun-facts-content-area {
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 50px;
}

.fun-facts-content-area .col-lg-3:last-child .single-fun-fact {
  border-right: none;
}

.fun-facts-content-area .single-fun-fact {
  text-align: center;
  position: relative;
  border-right: 1px dashed #209e2e;
}

.fun-facts-content-area .single-fun-fact h3 {
  position: relative;
  color: #209e2e;
  margin-bottom: 5px;
  line-height: 1;
  font-size: 50px;
  font-weight: 300;
}

.fun-facts-content-area .single-fun-fact h3 .sign-icon {
  display: inline-block;
  font-size: 50px;
  position: relative;
  top: 2px;
  right: 10px;
}

.fun-facts-content-area .single-fun-fact p {
  line-height: initial;
  color: #209e2e;
  margin: 0 0 0 0;
  font-weight: 400;
  font-size: 16px;
}

.fun-facts-content-area.fun-facts-top {
  position: relative;
  z-index: 1;
  margin-top: -85px;
}

/*================================================
Summary Section CSS
=================================================*/
.summary-section {
  position: relative;
}

.summary-content span {
  font-size: 15px;
  color: #209e2e;
  text-transform: uppercase;
}

.summary-content h3 {
  font-size: 38px;
  margin: 16px 0 20px 0;
}

.summary-content .summary-btn {
  margin-top: 30px;
}

.summary-content .summary-btn .optional-btn {
  margin-left: 0;
}

.summary-shape-image {
  position: absolute;
  bottom: 55px;
  z-index: -1;
}

.summary-shape-image-two {
  position: absolute;
  bottom: 55px;
  z-index: -1;
  right: 0;
}

/*================================================
Services Section CSS
=================================================*/
.services-content-area {
  position: relative;
}

.services-content-area .services-slider .services-item {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.services-content-area .services-slider .services-item .icon i {
  font-size: 70px;
  color: #209e2e;
  line-height: normal;
}

.services-content-area .services-slider .services-item h3 {
  font-size: 18px;
  color: #616161;
  margin: 2px 0 0 0;
  position: relative;
  font-weight: 500;
  z-index: 1;
}

.services-content-area .services-slider .services-item h3::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #209e2e;
  left: 0;
  right: 0;
  bottom: -25px;
  margin: auto;
  border-radius: 30px;
}

.services-content-area .services-slider.owl-theme .owl-nav {
  opacity: 0;
  margin-top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.services-content-area .services-slider.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: -16px;
  top: 50%;
  height: 40px;
  width: 40px;
  line-height: 38px;
  background-color: #209e2e !important;
  border: 1px solid #209e2e !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff !important;
  font-size: 16px;
  border-radius: 50px;
}

.services-content-area .services-slider.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -16px;
}

.services-content-area .services-slider.owl-theme .owl-nav [class*=owl-]:hover {
  color: #209e2e !important;
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}

.services-content-area .services-slider.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.services-content-area .services-slider.owl-carousel .owl-stage-outer {
  padding-bottom: 5px;
}

.services-content-area .services-slider.owl-carousel .owl-stage-outer .owl-stage {
  border-bottom: 1px dashed #209e2e;
}

/*================================================
Cultivation Section CSS
=================================================*/
.cultivation-section {
  background-color: #eef7ef;
}

.single-cultivation {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-cultivation .cultivation-image {
  position: relative;
  border-radius: 50%;
}

.single-cultivation .cultivation-image img {
  border-radius: 50%;
  width: 100%;
}

.single-cultivation .cultivation-image::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background: #209e2e;
  opacity: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  visibility: hidden;
}

.single-cultivation .cultivation-image .details-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 70%;
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
  z-index: 2;
  color: #209e2e;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-color: #eafef1;
  margin: 0 auto;
  line-height: 40px;
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
}

.single-cultivation .cultivation-image .details-btn:hover {
  background-color: #209e2e;
  color: #ffffff;
  border-color: #209e2e;
}

.single-cultivation .cultivation-content {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 80px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-cultivation .cultivation-content h3 {
  margin-bottom: 0;
  font-size: 25px;
  color: #ffffff;
  font-weight: 500;
}

.single-cultivation .cultivation-content span {
  display: block;
  color: #827f8d;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 5px;
}

.single-cultivation:hover .cultivation-image::before {
  opacity: .60;
  visibility: visible;
}

.single-cultivation:hover .cultivation-image .details-btn {
  opacity: 1;
  visibility: visible;
}

.single-cultivation:hover .cultivation-content {
  bottom: 140px;
}

/*================================================
Fisherman Section CSS
=================================================*/
.fisherman-content span {
  font-size: 15px;
  color: #f59818;
  text-transform: capitalize;
}

.fisherman-content h3 {
  font-size: 15px;
  margin: 0;
  color: #454545;
  text-transform: uppercase;
}

.fisherman-content p {
  margin: 0 0 0 0;
}

.fisherman-content .fisherman-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: -10px;
  margin-right: -10px;
}

.fisherman-content .fisherman-list li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.fisherman-content .fisherman-list i {
  display: inline-block;
  height: 25px;
  width: 25px;
  line-height: 25px;
  background-color: #eafef1;
  color: #209e2e;
  border: 1px solid #209e2e;
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-right: 6px;
}

.fisherman-content .fisherman-btn {
  margin-top: 35px;
}

.fisherman-content .fisherman-btn .optional-btn {
  margin-left: 0;
}

.fisherman-image img {
  width: 100%;
}

/*================================================
Cowman Section CSS
=================================================*/
.cowman-content span {
  font-size: 15px;
  color: #209e2e;
  text-transform: uppercase;
}

.cowman-content h3 {
  font-size: 40px;
  margin: 16px 0 20px 0;
}

.cowman-content p {
  margin: 0 0 0 0;
}

.cowman-content .cowman-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: -10px;
  margin-right: -10px;
}

.cowman-content .cowman-list li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.cowman-content .cowman-list i {
  display: inline-block;
  height: 25px;
  width: 25px;
  line-height: 25px;
  background-color: #eafef1;
  color: #209e2e;
  border: 1px solid #209e2e;
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-right: 6px;
}

.cowman-content .cowman-btn {
  margin-top: 35px;
}

.cowman-content .cowman-btn .optional-btn {
  margin-left: 0;
}

.cowman-image img {
  width: 100%;
}

/*================================================
Testimonials Section CSS
=================================================*/
.testimonials-section {
  position: relative;
  background-image: url(../../assets/img/testimonials-bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

.testimonials-section::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #000000;
  opacity: 0.5;
  z-index: -1;
}

.testimonials-title {
  text-align: center;
  margin-bottom: 50px;
}

.testimonials-title span {
  font-size: 15px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 400;
}

.testimonials-title h3 {
  font-size: 38px;
  color: #ffffff;
  max-width: 430px;
  margin: 12px auto 0;
}

.testimonials-slider {
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.testimonials-slider .testimonials-item {
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border: 1px dashed #209e2e;
  position: relative;
}

.testimonials-slider .testimonials-item .testimonials-content h3 {
  font-size: 20px;
  color: #616161;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.testimonials-slider .testimonials-item .testimonials-content span {
  font-size: 16px;
  color: #209e2e;
  font-weight: 400;
}

.testimonials-slider .testimonials-item .testimonials-content p {
  font-size: 16px;
  margin: 16px 0 0 0;
}

.testimonials-slider .testimonials-info {
  position: relative;
  margin-top: 30px;
}

.testimonials-slider .testimonials-info img {
  display: inline-block;
  width: 100px;
  border-radius: 50px;
}

.testimonials-slider .testimonials-info .icon {
  font-size: 16px;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #e8f5ea;
  text-align: center;
  border-radius: 50px;
  color: #209e2e;
}

.testimonials-slider .testimonials-info .star-list {
  padding-left: 0;
  margin-bottom: 0;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  display: inline-block;
  padding: 5px;
  list-style-type: none;
  border-radius: 5px;
  border-radius: 4px;
  position: relative;
  left: -28px;
  top: 20px;
}

.testimonials-slider .testimonials-info .star-list li {
  display: inline-block;
}

.testimonials-slider .testimonials-info .star-list li i {
  color: #f4a708;
  font-size: 12px;
}

.testimonials-slider .owl-item.active.center .testimonials-item {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
}

.testimonials-slider.owl-theme .owl-nav {
  opacity: 0;
  margin-top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.testimonials-slider.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: 12%;
  top: 50%;
  height: 40px;
  width: 40px;
  line-height: 38px;
  background-color: #209e2e !important;
  border: 1px solid #209e2e !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff !important;
  font-size: 18px;
  border-radius: 50px;
}

.testimonials-slider.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 12%;
}

.testimonials-slider.owl-theme .owl-nav [class*=owl-]:hover {
  color: #209e2e !important;
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}

.testimonials-slider.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

/*================================================
Blog Section CSS
=================================================*/
.single-blog-item {
  text-align: left;
  padding: 30px;
  margin-bottom: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-blog-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #209e2e;
  opacity: 1;
  z-index: -1;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  border-radius: 5px;
}

.single-blog-item.bg-1 {
  background-image: url(../../assets/img/blog/1.jpg);
}

.single-blog-item.bg-2 {
  background-image: url(../../assets/img/blog/2.jpg);
}

.single-blog-item.bg-3 {
  background-image: url(../../assets/img/blog/3.jpg);
}

.single-blog-item span {
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
  position: relative;
  padding-left: 18px;
}

.single-blog-item span::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #ffffff;
  left: 0;
  top: 4px;
  border-radius: 30px;
}

.single-blog-item h3 {
  font-size: 20px;
  color: #ffffff;
  margin: 10px 0 14px 0;
  font-weight: 500;
  line-height: 1.4;
}

.single-blog-item p {
  color: #ffffff;
  margin: 0 0 0 0;
}

.single-blog-item .blog-btn {
  margin-top: 20px;
}

.single-blog-item .blog-btn .blog-btn-one {
  display: inline-block;
  text-align: center;
  z-index: 1;
  color: #209e2e !important;
  text-transform: capitalize;
  border-radius: 50px;
  font-weight: 400;
  padding: 12px 30px;
  background-color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-blog-item .blog-btn .blog-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.single-blog-item .blog-btn .blog-btn-one:hover {
  background-color: #209e2e;
  color: #ffffff !important;
}

.single-blog-item:hover::before {
  opacity: 0.4;
}

.single-blog-item:hover .blog-btn-one {
  background-color: #209e2e;
  color: #ffffff !important;
}

.single-blog-item-two {
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  margin-bottom: 30px;
}

.single-blog-item-two .blog-image {
  position: relative;
}

.single-blog-item-two .blog-image::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #000000;
  opacity: 0.5;
}

.single-blog-item-two .blog-content {
  position: absolute;
  bottom: 0;
  padding: 25px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-blog-item-two .blog-content span {
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
  position: relative;
  padding-left: 18px;
  display: inline-block;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
}

.single-blog-item-two .blog-content span::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #ffffff;
  left: 0;
  top: 4px;
  border-radius: 30px;
}

.single-blog-item-two .blog-content h3 {
  font-size: 20px;
  color: #ffffff;
  margin: 10px 0 14px 0;
  font-weight: 500;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
  position: relative;
  line-height: 30px;
}

.single-blog-item-two .blog-content p {
  color: #ffffff;
  margin: 0 0 0 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: absolute;
  bottom: 0;
  opacity: 0;
  z-index: 1;
}

.single-blog-item-two .blog-content .blog-btn {
  margin-top: 18px;
}

.single-blog-item-two .blog-content .blog-btn .blog-btn-one {
  display: inline-block;
  text-align: center;
  z-index: 1;
  color: #209e2e !important;
  text-transform: capitalize;
  border-radius: 50px;
  font-weight: 400;
  padding: 12px 30px;
  background-color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border: 1px solid #ffffff;
  position: relative;
  z-index: 1;
}

.single-blog-item-two .blog-content .blog-btn .blog-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.single-blog-item-two .blog-content .blog-btn .blog-btn-one:hover {
  background-color: #209e2e;
  color: #ffffff !important;
}

.single-blog-item-two::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
}

.single-blog-item-two:hover::before {
  height: 100%;
}

.single-blog-item-two:hover span {
  -webkit-transform: translateY(-80px);
  transform: translateY(-80px);
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-blog-item-two:hover h3 {
  -webkit-transform: translateY(-72px);
  transform: translateY(-72px);
}

.single-blog-item-two:hover p {
  opacity: 1;
  -webkit-transform: translateY(-95px);
  transform: translateY(-95px);
}

.single-blog-item-three {
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  margin-bottom: 30px;
}

.single-blog-item-three .blog-image {
  position: relative;
}

.single-blog-item-three .blog-image::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #000000;
  opacity: 0.5;
}

.single-blog-item-three .blog-content {
  position: absolute;
  bottom: 0;
  padding: 25px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-blog-item-three .blog-content span {
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
  position: relative;
  padding-left: 18px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  display: inline-block;
  z-index: 1;
}

.single-blog-item-three .blog-content span::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #ffffff;
  left: 0;
  top: 4px;
  border-radius: 30px;
}

.single-blog-item-three .blog-content h3 {
  font-size: 20px;
  color: #ffffff;
  margin: 10px 0 14px 0;
  font-weight: 500;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  line-height: 1.4;
}

.single-blog-item-three .blog-content p {
  color: #ffffff;
  margin: 0 0 0 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: absolute;
  opacity: 0;
  bottom: 0;
}

.single-blog-item-three .blog-content .blog-btn {
  margin-top: 20px;
}

.single-blog-item-three .blog-content .blog-btn .blog-btn-one {
  display: inline-block;
  text-align: center;
  z-index: 1;
  color: #209e2e !important;
  text-transform: capitalize;
  border-radius: 50px;
  font-weight: 400;
  padding: 12px 30px;
  background-color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border: 1px solid #ffffff;
}

.single-blog-item-three .blog-content .blog-btn .blog-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.single-blog-item-three .blog-content .blog-btn .blog-btn-one:hover {
  background-color: #209e2e;
  color: #ffffff !important;
  border: 1px solid #209e2e;
}

.single-blog-item-three:hover span {
  -webkit-transform: translateY(-90px);
  transform: translateY(-90px);
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-blog-item-three:hover h3 {
  -webkit-transform: translateY(-80px);
  transform: translateY(-80px);
}

.single-blog-item-three:hover p {
  opacity: 1;
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
}

/*================================================
Partner Section CSS
=================================================*/
.partner-section {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #eef7ef;
}

.partner-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.partner-list .partner-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 5px;
  padding-right: 5px;
}

.partner-list .partner-item a {
  display: block;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  margin-top: 30px;
}

.partner-list .partner-item:nth-child(1) {
  border-bottom: 1px dashed #209e2e;
}

.partner-list .partner-item:nth-child(2) {
  border-bottom: 1px dashed #209e2e;
  border-right: 1px dashed #209e2e;
}

.partner-list .partner-item:nth-child(3) {
  border-bottom: 1px dashed #209e2e;
  border-right: 1px dashed #209e2e;
}

.partner-list .partner-item:nth-child(4) {
  border-bottom: 1px dashed #209e2e;
}

.partner-list .partner-item:nth-child(5) {
  border-bottom: 1px dashed #209e2e;
}

.partner-list .partner-item:nth-child(6) {
  margin-left: 20%;
  border-right: 1px dashed #209e2e;
}

.partner-list .partner-item:nth-child(7) {
  border-right: 1px dashed #209e2e;
}

/*================================================
Farm Area CSS
=================================================*/
.farm-item {
  position: relative;
  background-color: #d2ecd4;
  padding: 40px 40px 225px;
}

.farm-item .farm-content span {
  font-size: 14px;
  color: #209e2e;
}

.farm-item .farm-content h3 {
  font-size: 25px;
  margin: 5px 0 14px 0;
  color: #5a5959;
}

.farm-item .farm-content .farm-btn .farm-btn-one {
  display: inline-block;
  border: none;
  padding: 12px 30px;
  background-color: #eafef1;
  color: #209e2e;
  border: 1px solid #209e2e;
  border-radius: 30px;
}

.farm-item .farm-content .farm-btn .farm-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.farm-item .farm-image {
  position: absolute;
  bottom: 0;
  right: -15px;
}

.farm-item.bg-fdedce {
  background-color: #fdedce;
}

.farm-item.bg-fdedce .farm-btn .farm-btn-one {
  display: inline-block;
  border: none;
  padding: 12px 30px;
  background-color: #ffdd9b;
  color: #5a5959;
  border: 1px solid #5a5959;
  border-radius: 30px;
}

.farm-item.bg-fdedce .farm-btn .farm-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  background-color: #fefaf0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.partner-area.bg-white {
  background-color: #ffffff;
}

.partner-area.bg-white .partner-slider::before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background-color: #ffffff;
  height: 100%;
  width: 1px;
  z-index: 1;
}

.partner-slider::before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background-color: #fefaf0;
  height: 100%;
  width: 1px;
  z-index: 1;
}

.partner-slider .partner-item {
  text-align: center;
}

.partner-slider .partner-item img {
  display: inline-block;
  width: 120px;
  margin-bottom: 30px;
}

.partner-slider .partner-item a {
  display: block;
  text-align: center;
  position: relative;
  border-bottom: 1px dashed #209e2e;
}

.partner-slider .owl-item {
  border-right: 1px dashed #209e2e;
}

.partner-slider.owl-theme .owl-nav {
  opacity: 0;
  margin-top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.partner-slider.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: -45px;
  top: 50%;
  height: 40px;
  width: 40px;
  line-height: 38px;
  background-color: #209e2e !important;
  border: 1px solid #209e2e !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff !important;
  font-size: 16px;
  border-radius: 50px;
  z-index: 1;
}

.partner-slider.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -45px;
}

.partner-slider.owl-theme .owl-nav [class*=owl-]:hover {
  color: #ffffff !important;
  background-color: #209e2e !important;
  border: 1px solid #209e2e !important;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}

.partner-slider.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.partner-shape {
  position: absolute;
  top: 20%;
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  -webkit-animation: moveBounce 5s linear infinite;
  animation: moveBounce 5s linear infinite;
}

.partner-shape-img1 {
  position: absolute;
  right: 0;
  top: 20%;
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
  z-index: -1;
  -webkit-animation: moveBounce 5s linear infinite;
  animation: moveBounce 5s linear infinite;
}

/*================================================
Cow Section CSS
=================================================*/
.cow-section {
  padding-bottom: 50px;
}

.cow-btn {
  margin-top: 50px;
}

.cow-btn .cow-btn-one {
  display: inline-block;
  border: none;
  padding: 12px 30px;
  background-color: #209e2e;
  color: #ffffff;
  border: 1px solid #209e2e;
  border-radius: 30px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  margin-right: 20px;
}

.cow-btn .cow-btn-one:hover {
  background-color: #ffffff;
  color: #209e2e;
}

.cow-btn .cow-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.cow-btn .cow-btn-two {
  display: inline-block;
  border: none;
  padding: 12px 30px;
  background-color: #ffdd9b;
  color: #5c5b5a;
  border: 1px solid #5a5959;
  border-radius: 30px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.cow-btn .cow-btn-two:hover {
  background-color: #209e2e;
  color: #ffffff;
  border: 1px solid #209e2e;
}

.cow-btn .cow-btn-two i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.cow-image {
  text-align: center;
}

/*================================================
Faq Section CSS
=================================================*/
.faq-section {
  background-image: url(../../assets/img/faq-bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.faq-section::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #f6fbf7;
  opacity: 0.5;
  z-index: -1;
}

.faq-content span {
  font-size: 14px;
  color: #209e2e;
  font-weight: 400;
  text-transform: uppercase;
}

.faq-content h3 {
  font-size: 38px;
  margin: 14px 0 0 0;
}

.faq-content .faq-btn {
  margin-top: 30px;
}

.faq-accordion .accordion {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.faq-accordion .accordion .accordion-item {
  margin-bottom: 15px;
  border: none;
  background-color: transparent;
}

.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item .accordion-title {
  font-size: 20px;
  color: #696d8b;
  font-weight: 600;
}

.faq-accordion .accordion .accordion-item .accordion-title i {
  color: #209e2e;
  font-size: 16px;
  line-height: normal;
  margin-right: 5px;
}

.faq-accordion .accordion .accordion-item .accordion-content {
  padding-top: 10px;
  display: none;
}

.faq-accordion .accordion .accordion-item .accordion-content.show {
  display: block;
}

/*================================================
Cultivation Area CSS
=================================================*/
.cultivation-area {
  background-image: url(../../assets/img/cultivation-bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.cultivation-title {
  text-align: center;
  margin-bottom: 50px;
}

.cultivation-title span {
  font-size: 15px;
  color: #ffffff;
  text-transform: uppercase;
}

.cultivation-title h3 {
  font-size: 38px;
  color: #ffffff;
}

.cultivation-item {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.cultivation-item .cultivation-image {
  position: relative;
  border-radius: 50%;
}

.cultivation-item .cultivation-image img {
  border-radius: 50%;
  width: 100%;
}

.cultivation-item .cultivation-image::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background: #209e2e;
  opacity: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  visibility: hidden;
}

.cultivation-item .cultivation-image .details-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 75%;
  -webkit-transform: translateY(-75%);
  transform: translateY(-75%);
  z-index: 2;
  color: #209e2e;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-color: #eafef1;
  margin: 0 auto;
  line-height: 42px;
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
}

.cultivation-item .cultivation-image .details-btn:hover {
  background-color: #209e2e;
  color: #ffffff;
  border-color: #209e2e;
}

.cultivation-item .cultivation-content {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 80px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.cultivation-item .cultivation-content h3 {
  margin-bottom: 0;
  font-size: 25px;
  color: #209e2e;
  font-weight: 500;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.cultivation-item .cultivation-content span {
  display: block;
  font-size: 16px;
  color: #209e2e;
  font-weight: 400;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  margin-bottom: 5px;
}

.cultivation-item .cultivation-content .icon i {
  font-size: 50px;
  line-height: normal;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.cultivation-item:hover .cultivation-image::before {
  opacity: 0.6;
  visibility: visible;
}

.cultivation-item:hover .cultivation-image .details-btn {
  opacity: 1;
  visibility: visible;
}

.cultivation-item:hover .cultivation-content {
  bottom: 130px;
}

.cultivation-item:hover h3 {
  color: #ffffff;
}

.cultivation-item:hover span {
  color: #ffffff;
}

.cultivation-item:hover .icon i {
  color: #ffffff;
}

/*================================================
Client Section CSS
=================================================*/
.client-section {
  background-image: url(../../assets/img/client-bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.client-title {
  text-align: center;
  margin-bottom: 50px;
}

.client-title span {
  font-size: 15px;
  color: #209e2e;
  text-transform: uppercase;
  font-weight: 400;
}

.client-title h3 {
  font-size: 38px;
  max-width: 430px;
  margin: 10px auto 0;
}

.client-slider {
  position: relative;
}

.client-slider .client-item {
  text-align: center;
}

.client-slider .client-item .client-info {
  position: relative;
}

.client-slider .client-item .client-info img {
  display: inline-block;
  width: 100px;
  border-radius: 50px;
}

.client-slider .client-item .client-info .icon {
  font-size: 16px;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #e8f5ea;
  text-align: center;
  border-radius: 50px;
  color: #209e2e;
}

.client-slider .client-item .client-info .star-list {
  padding-left: 0;
  margin-bottom: 0;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  display: inline-block;
  padding: 5px;
  list-style-type: none;
  border-radius: 5px;
  border-radius: 4px;
  position: relative;
  left: -28px;
  top: 20px;
}

.client-slider .client-item .client-info .star-list li {
  display: inline-block;
  margin-right: 5px;
}

.client-slider .client-item .client-info .star-list li:last-child {
  margin-right: 0;
}

.client-slider .client-item .client-info .star-list li i {
  color: #f4a708;
  font-size: 12px;
}

.client-slider .client-item .client-content {
  margin-top: 20px;
}

.client-slider .client-item .client-content p {
  max-width: 690px;
  margin: 0 auto 20px;
  font-style: italic;
  font-size: 18px;
}

.client-slider .client-item .client-content h3 {
  font-size: 20px;
  color: #616161;
  margin: 0 0 0 0;
  display: inline-block;
}

.client-slider .client-item .client-content span {
  display: inline-block;
  font-size: 15px;
  color: #209e2e;
}

.client-slider.owl-theme .owl-nav {
  opacity: 0;
  margin-top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.client-slider.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: 0;
  top: 50%;
  height: 40px;
  width: 40px;
  line-height: 38px;
  background-color: #209e2e !important;
  border: 1px solid #209e2e !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff !important;
  font-size: 16px;
  border-radius: 50px;
}

.client-slider.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 0;
}

.client-slider.owl-theme .owl-nav [class*=owl-]:hover {
  color: #209e2e !important;
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}

.client-slider.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

/*================================================
Triple Section CSS
=================================================*/
.triple-section {
  padding-top: 60px;
}

.triple-item {
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 5px;
  z-index: 1;
}

.triple-item .triple-image img {
  border-radius: 5px;
  width: 100%;
}

.triple-item .triple-content {
  position: absolute;
  bottom: 0;
  padding: 35px;
  width: 100%;
}

.triple-item .triple-content span {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  position: relative;
  z-index: 1;
}

.triple-item .triple-content h3 {
  font-size: 35px;
  color: #ffffff;
  margin: 12px 0 0 0;
  position: relative;
  z-index: 1;
}

.triple-item .triple-content .triple-btn {
  position: relative;
  z-index: 1;
  margin-top: 20px;
}

.triple-item .triple-content .triple-btn .triple-btn-one {
  display: inline-block;
  color: #209e2e !important;
  text-transform: capitalize;
  border-radius: 50px;
  font-weight: 400;
  padding: 12px 30px;
  background-color: #eafef1;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border: 1px solid #eafef1;
}

.triple-item .triple-content .triple-btn .triple-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.triple-item .triple-shape {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: relative;
}

.triple-item .triple-shape img {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.triple-item .triple-shape img:last-child {
  opacity: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.triple-item:hover .triple-shape img:last-child {
  opacity: 1;
}

/*================================================
Counter Section CSS
=================================================*/
.counter-section {
  background-color: #fdf8ef;
  position: relative;
  z-index: 1;
}

.col-lg-3:last-child .single-counter {
  border-right: none;
}

.single-counter {
  text-align: center;
  position: relative;
  border-right: 1px dashed #209e2e;
  margin-bottom: 30px;
}

.single-counter h3 {
  position: relative;
  color: #209e2e;
  margin-bottom: 5px;
  line-height: 1;
  font-size: 50px;
  font-weight: 300;
}

.single-counter h3 .sign-icon {
  display: inline-block;
  font-size: 50px;
  position: relative;
  top: 4px;
  right: 10px;
}

.single-counter p {
  line-height: initial;
  color: #209e2e;
  margin: 0 0 0 0;
  font-weight: 400;
  font-size: 16px;
}

/*================================================
Brief Section CSS
=================================================*/
.brief-section {
  background-image: url(../../assets/img/brief-bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.brief-btn {
  margin-top: 30px;
}

.brief-image {
  text-align: center;
}

/*================================================
Sector Section CSS
=================================================*/
.sector-section {
  background-color: #fffbf3;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.sector-content {
  max-width: 590px;
  margin-left: auto;
}

.sector-content span {
  font-size: 15px;
  color: #209e2e;
  font-weight: 400;
}

.sector-content h3 {
  font-size: 38px;
  margin: 10px 0 16px 0;
}

.sector-content p {
  color: #7a7e9a;
  margin: 0 0 0 0;
}

.sector-content .sector-btn {
  margin-top: 25px;
}

.cloud-img1 {
  position: absolute;
  left: 10%;
  bottom: 48%;
  z-index: -1;
  -webkit-animation: moveLeftBounce 3s linear infinite;
  animation: moveLeftBounce 3s linear infinite;
}

.cloud-img2 {
  position: absolute;
  left: 20%;
  top: 10%;
  z-index: -1;
  -webkit-animation: moveScale 3s linear infinite;
  animation: moveScale 3s linear infinite;
}

.cloud-img3 {
  position: absolute;
  left: 65%;
  right: auto;
  top: 50px;
  z-index: -1;
  -webkit-animation: moveLeftBounce 3s linear infinite;
  animation: moveLeftBounce 3s linear infinite;
}

.sun-image {
  position: absolute;
  left: 50%;
  top: 10%;
  z-index: -1;
}

.sun-image img {
  -webkit-animation-name: rotateMe;
  animation-name: rotateMe;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes rotateMe {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*================================================
Faq Area CSS
=================================================*/
.faq-area {
  border-bottom: 1px solid #f1f1f1;
}

.faq-accordion-content .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.faq-accordion-content .accordion .accordion-item {
  display: block;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #ffffff;
  margin-bottom: 10px;
  border: none;
}

.faq-accordion-content .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-accordion-content .accordion .accordion-title {
  padding: 18px 20px 18px 55px;
  color: #333333;
  position: relative;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  display: block;
  font-size: 16px;
  font-weight: 600;
}

.faq-accordion-content .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #209e2e;
  color: #ffffff;
}

.faq-accordion-content .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 13px;
}

.faq-accordion-content .accordion .accordion-title.active {
  border-bottom-color: #eeeeee;
}

.faq-accordion-content .accordion .accordion-title.active i::before {
  content: "\f068";
}

.faq-accordion-content .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px;
  font-size: 15px;
}

.faq-accordion-content .accordion .accordion-content.show {
  display: block;
}

.faq-contact {
  margin-top: 70px;
}

.faq-contact-form {
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
}

.faq-contact-form form .form-group {
  margin-bottom: 15px;
}

.faq-contact-form form .form-control {
  background-color: #ffffff;
  border: none;
  -webkit-box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  height: 60px;
  font-size: 15px;
}

.faq-contact-form form textarea.form-control {
  height: auto;
  padding-top: 15px;
  line-height: initial;
}

.faq-contact-form form .btn {
  margin-top: 8px;
  -webkit-box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}

.faq-contact-form form .help-block ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 5px;
  margin-bottom: 0;
}

.faq-contact-form form .help-block ul li {
  color: red;
  font-weight: 300;
  text-align: left;
}

.faq-contact-form form #msgSubmit {
  margin-bottom: 0;
  text-align: center !important;
}

.faq-contact-form form #msgSubmit.text-danger,
.faq-contact-form form #msgSubmit.text-success {
  margin-top: 8px;
  font-size: 22px;
  font-weight: 300;
}

.faq-contact-form .send-btn {
  margin-top: 10px;
}

.faq-contact-form .send-btn .optional-btn {
  margin-left: 0;
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-title {
  text-align: center;
  margin-bottom: 50px;
}

.feedback-title span {
  font-size: 15px;
  color: #209e2e;
  text-transform: uppercase;
  font-weight: 400;
}

.feedback-title h3 {
  font-size: 38px;
  max-width: 430px;
  margin: 8px auto 0;
}

.feedback-slider {
  position: relative;
}

.feedback-slider .feedback-item {
  text-align: center;
  background-color: #e9f5ea;
  padding: 30px;
  margin-top: 36px;
  border: 1px dashed #209e2e;
  position: relative;
}

.feedback-slider .feedback-item .feedback-info {
  position: relative;
}

.feedback-slider .feedback-item .feedback-info img {
  display: inline-block;
  width: 100px;
  border-radius: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.feedback-slider .feedback-item .feedback-info .star-list {
  padding-left: 0;
  margin-bottom: 0;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  display: inline-block;
  padding: 5px;
  list-style-type: none;
  border-radius: 5px;
  border-radius: 4px;
  position: relative;
  left: 0;
  top: 20px;
}

.feedback-slider .feedback-item .feedback-info .star-list li {
  display: inline-block;
  margin-right: 5px;
}

.feedback-slider .feedback-item .feedback-info .star-list li:last-child {
  margin-right: 0;
}

.feedback-slider .feedback-item .feedback-info .star-list li i {
  color: #f4a708;
  font-size: 12px;
}

.feedback-slider .feedback-item .feedback-content {
  margin-top: 50px;
}

.feedback-slider .feedback-item .feedback-content h3 {
  font-size: 20px;
  margin: 0 0 0 0;
}

.feedback-slider .feedback-item .feedback-content span {
  color: #209e2e;
  font-size: 15px;
  font-weight: 400;
}

.feedback-slider .feedback-item .feedback-content p {
  color: #686d8a;
  margin: 15px 0 0 0;
}

.feedback-slider .owl-item.active.center .feedback-item {
  background-color: #ffffff !important;
  border: 1px dashed #209e2e !important;
}

.feedback-slider.owl-theme .owl-nav.disabled+.owl-dots {
  line-height: .01;
  margin-top: 45px;
  margin-bottom: 0;
  position: absolute;
  display: inline-block;
  left: 0;
  right: 0;
}

.feedback-slider.owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 20px;
  background-color: transparent;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 50%;
  border: 1px solid #209e2e;
  position: relative;
}

.feedback-slider.owl-theme .owl-dots .owl-dot span::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #209e2e;
  border-radius: 50%;
  margin: 4px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.feedback-slider.owl-theme .owl-dots .owl-dot:hover span::before,
.feedback-slider.owl-theme .owl-dots .owl-dot.active span::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
  position: relative;
  background-image: url(../../assets/img/subscribe-bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.subscribe-area::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #f6fbf7;
  left: 0;
  top: 0;
  opacity: 0.5;
  z-index: -1;
}

.subscribe-content {
  text-align: center;
  margin-bottom: 50px;
}

.subscribe-content p {
  color: #7a7e9a;
  max-width: 600px;
  margin: auto;
}

.subscribe-content h2 {
  margin-bottom: 14px;
  margin-left: auto;
  margin-right: auto;
  font-size: 38px;
}

.newsletter-form {
  max-width: 780px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.newsletter-form .input-newsletter {
  display: inline-block;
  width: 70%;
  background-color: #ffffff;
  border: none;
  height: 70px;
  padding-left: 25px;
  border-radius: 5px;
  outline: 0;
  color: #333333;
  border-radius: 50px;
  border: 1px dashed #209e2e;
}

.newsletter-form .input-newsletter::-webkit-input-placeholder {
  color: #7a7e9a;
}

.newsletter-form .input-newsletter:-ms-input-placeholder {
  color: #7a7e9a;
}

.newsletter-form .input-newsletter::-ms-input-placeholder {
  color: #7a7e9a;
}

.newsletter-form .input-newsletter::placeholder {
  color: #7a7e9a;
}

.newsletter-form button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #209e2e;
  color: #ffffff;
  border: none;
  height: 70px;
  padding: 0 45px;
  border-radius: 50px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  line-height: 50px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}

.newsletter-form button:hover {
  background-color: #333333;
  color: #ffffff;
}

.newsletter-form button i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.newsletter-form #validator-newsletter {
  color: red;
  margin-top: 10px;
}

/*================================================
Country Area CSS
=================================================*/
.country-area {
  overflow: hidden;
}

.country-item .country-content {
  margin-bottom: 25px;
}

.country-item .country-content span {
  font-size: 15px;
  color: #209e2e;
  font-weight: 400;
  text-transform: uppercase;
}

.country-item .country-content h3 {
  font-size: 38px;
  margin: 10px 0 16px 0;
}

.country-item .country-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: -10px;
  margin-right: -10px;
}

.country-item .country-list li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
  margin-top: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  color: #333333;
  font-weight: 500;
}

.country-item .country-list i {
  font-size: 16px;
  margin-right: 4px;
}

.country-item h3 {
  font-size: 25px;
  margin: 0 0 0 0;
}

.country-item .country-btn {
  margin-top: 30px;
}

.map-img {
  position: relative;
  -webkit-transition: .6s;
  transition: .6s;
  z-index: 1;
  text-align: center;
}

.map-img .location {
  position: absolute;
  left: 14%;
  top: 40%;
  width: auto;
  height: auto;
}

.map-img .location a::before {
  content: "";
  position: absolute;
  left: -4px;
  bottom: -4px;
  border: 4px solid #209e2e;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  -webkit-animation-name: ripple;
  animation-name: ripple;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  background: #ffffff;
}

.map-img .location a .location-info {
  position: absolute;
  left: -60px;
  bottom: 30px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  width: 160px;
  background: #ffffff;
  text-align: center;
  padding: 5px 15px;
  border-radius: 3px;
  z-index: 1;
  -webkit-box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.06);
}

.map-img .location a .location-info::before {
  content: "";
  position: absolute;
  left: 50px;
  bottom: -10px;
  width: 25px;
  height: 25px;
  background: #ffffff;
  z-index: -1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.06);
}

.map-img .location a .location-info h5 {
  font-size: 16px;
  margin: 5px 0 5px 0;
  color: #209e2e;
}

.map-img .location.location2 {
  right: 50%;
  left: auto;
  top: 25%;
}

.map-img .location.location3 {
  right: 96%;
  left: auto;
  top: 76%;
}

.map-img .location.location4 {
  right: 50%;
  left: auto;
  top: 60%;
}

.map-img .location.location5 {
  right: 56%;
  left: auto;
  top: 90%;
}

.map-img .location.location6 {
  right: 20%;
  left: auto;
  top: 90%;
}

.map-img .location a:hover .location-info,
.map-img .location a .map-img .location a:focus .location-info,
.map-img .location a .map-img .location a.active .location-info {
  bottom: 40px;
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  position: relative;
  z-index: 1;
  background-image: url(../../assets/img/page-title-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}

.page-title-area::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000000;
  left: 0;
  top: 0;
  opacity: 0.7;
  z-index: -1;
}

.page-title-content {
  text-align: center;
  margin-top: 100px;
}

.page-title-content h2 {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 42px;
  font-weight: 700;
}

.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 12px;
  margin-bottom: 0;
}

.page-title-content ul li {
  color: #ffffff;
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 17px;
  margin-left: 10px;
  margin-right: 10px;
}

.page-title-content ul li a {
  display: inline-block;
  color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.page-title-content ul li a:hover {
  color: #209e2e;
}

.page-title-content ul li::before {
  content: '';
  position: absolute;
  right: -15px;
  top: 11px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #209e2e;
}

.page-title-content ul li:last-child::before {
  display: none;
}

.item-bg1 {
  background-image: url(../../assets/img/page-title-bg-2.jpg);
}

.item-bg2 {
  background-image: url(../../assets/img/page-title-bg-3.jpg);
}

/*================================================
Team Section CSS
=================================================*/
.single-team-item {
  margin-bottom: 30px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  text-align: center;
}

.single-team-item .team-image {
  position: relative;
}

.single-team-item .team-image img {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  width: 100%;
}

.single-team-item .team-image .team-social {
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  z-index: 2;
  text-decoration: none;
  opacity: 0;
  margin-top: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-team-item .team-image .team-social a {
  display: inline-block;
  color: #209e2e;
  font-size: 18px;
  margin: 0 6px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #209e2e;
  color: #ffffff;
  border-radius: 35px;
  font-size: 22px;
}

.single-team-item .team-image .team-social a:hover {
  background-color: #ffffff;
  color: #209e2e;
}

.single-team-item .team-content {
  padding: 25px;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-team-item .team-content h3 {
  margin-bottom: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  font-size: 24px;
  font-weight: 700;
}

.single-team-item .team-content span {
  display: block;
  margin-top: 8px;
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  font-weight: 500;
}

.single-team-item:hover {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.single-team-item:hover .team-image .team-social {
  opacity: 1;
  margin-top: 0;
  visibility: visible;
}

.single-team-item:hover .team-content {
  background-color: #209e2e;
}

.single-team-item:hover .team-content h3 {
  color: #ffffff;
}

.single-team-item:hover .team-content span {
  color: #ffffff;
}

/*================================================
Gallery Area CSS
=================================================*/
.gallery-area .container-fluid {
  max-width: 1920px;
}

.single-gallery-box {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}

.single-gallery-box::before {
  width: 100%;
  content: '';
  position: absolute;
  height: 100%;
  background-color: #209e2e;
  left: 0;
  top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  opacity: 0;
  visibility: hidden;
}

.single-gallery-box .gallery-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  z-index: 1;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #ffffff;
  color: #333333;
  font-size: 18px;
  margin-top: 15px;
  opacity: 0;
  visibility: hidden;
  border-radius: 50px;
}

.single-gallery-box .gallery-btn:hover {
  color: #ffffff;
  background-color: #333333;
}

.single-gallery-box:hover::before {
  opacity: .85;
  visibility: visible;
}

.single-gallery-box:hover .gallery-btn {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

/*================================================
Login In CSS
=================================================*/
.login-form {
  background: #ffffff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 5px;
  max-width: 600px;
  margin: auto;
}

.login-form .login-title {
  text-align: center;
  margin-bottom: 30px;
}

.login-form .login-title h3 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #343846;
}

.login-form .login-title p {
  margin-bottom: 30px;
}

.login-form form .form-group {
  margin-bottom: 15px;
}

.login-form form .form-control {
  height: 60px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  color: #ffffff;
  padding-left: 20px;
  background: transparent;
}

.login-form form .form-control:focus {
  border-color: #209e2e;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.login-form form .form-control::-webkit-input-placeholder {
  color: #666666;
}

.login-form form .form-control:-ms-input-placeholder {
  color: #666666;
}

.login-form form .form-control::-ms-input-placeholder {
  color: #666666;
}

.login-form form .form-control::placeholder {
  color: #666666;
}

.login-form form .form-check {
  margin-top: 18px;
  position: relative;
  z-index: 1;
}

.login-form form .form-check .form-check-label {
  color: #666666;
}

.login-form form .send-btn {
  margin-top: 15px;
}

.login-form form span {
  display: block;
  color: #777777;
}

.login-form form span a {
  color: #209e2e;
}

.login-form .forgot-password {
  margin-bottom: 0;
  margin-top: -25px;
  text-align: right;
}

.login-form .forgot-password a {
  color: #777777;
}

.login-form .forgot-password a:hover {
  color: #209e2e;
}

/*================================================
Sign Up CSS
=================================================*/
.signup-form {
  background: #ffffff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 5px;
  max-width: 600px;
  margin: auto;
}

.signup-form h3 {
  font-size: 28px;
  margin-bottom: 30px;
  text-align: center;
}

.signup-form form .form-group {
  margin-bottom: 15px;
}

.signup-form form .form-control {
  height: 60px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  color: #ffffff;
  padding-left: 20px;
  background: transparent;
}

.signup-form form .form-control:focus {
  border-color: #209e2e;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.signup-form form .form-control::-webkit-input-placeholder {
  color: #666666;
}

.signup-form form .form-control:-ms-input-placeholder {
  color: #666666;
}

.signup-form form .form-control::-ms-input-placeholder {
  color: #666666;
}

.signup-form form .form-control::placeholder {
  color: #666666;
}

.signup-form form .form-check {
  margin-top: 18px;
}

.signup-form form .form-check .form-check-label {
  color: #666666;
}

.signup-form form .send-btn {
  margin-top: 15px;
}

.signup-form form span {
  display: block;
  color: #666666;
}

.signup-form form span a {
  color: #209e2e;
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
  height: 100vh;
}

.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
}

.error-content h3 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 45px;
  margin-bottom: 15px;
}

.error-content p {
  max-width: 520px;
  margin: 0 auto 20px;
}

/*================================================
Contact Section CSS
=================================================*/
.contact-area .contact-form {
  -webkit-box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.contact-area .contact-form form {
  text-align: center;
}

.contact-area .contact-form form .form-group {
  margin-bottom: 15px;
}

.contact-area .contact-form form .form-control {
  background-color: #ffffff;
  border: none;
  -webkit-box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  height: 57px;
  color: #209e2e;
  border: 1px solid #ffffff;
}

.contact-area .contact-form form .form-control:focus {
  border: 1px solid #209e2e;
}

.contact-area .contact-form form .form-group {
  text-align: left;
}

.contact-area .contact-form form textarea.form-control {
  height: 120px;
  padding-top: 15px;
}

.contact-area .contact-form .default-btn {
  cursor: pointer;
  margin-top: 10px;
}

.contact-area .contact-form .list-unstyled {
  padding-left: 0;
  list-style: none;
  color: red;
}

.contact-area .contact-form .text-danger {
  color: #dc3545 !important;
  font-size: 20px;
  font-weight: 500 !important;
  margin: 16px 0 0 0;
}

.contact-box {
  padding-top: 100px;
  padding-bottom: 35px;
}

.single-contact-box {
  text-align: center;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  margin-bottom: 30px;
}

.single-contact-box i {
  line-height: 50px;
  width: 50px;
  height: 50px;
  border: 1px dashed #209e2e;
  border-radius: 50%;
  font-size: 20px;
  display: inline-block;
  color: #209e2e;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  margin-bottom: 20px;
}

.single-contact-box:hover i {
  background-color: #209e2e;
  color: #ffffff;
}

.single-contact-box .content-title h3 {
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 400;
}

.single-contact-box .content-title a {
  display: block;
  font-size: 16px;
  color: #747171;
  margin-bottom: 5px;
}

.single-contact-box .content-title a:hover {
  color: #209e2e;
}

/*================================================
Services Details CSS
=================================================*/
.services-details-overview {
  margin-bottom: 60px;
}

.services-details-overview:last-child {
  margin-bottom: 0;
}

.services-details-overview .services-details-desc h3 {
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: 600;
}

.services-details-overview .services-details-desc .features-text {
  margin-top: 25px;
}

.services-details-overview .services-details-desc .features-text h4 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}

.services-details-overview .services-details-desc .services-details-accordion {
  margin-top: 25px;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item {
  display: block;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #ffffff;
  margin-bottom: 10px;
  border: none;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item a {
  text-decoration: none;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title {
  padding: 15px 20px 15px 51px;
  color: #000000;
  position: relative;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  display: inline-block;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #209e2e;
  color: #ffffff;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 13px;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title.active {
  border-bottom-color: #eeeeee;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title.active i::before {
  content: "\f068";
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px 15px;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-content.show {
  display: block;
}

/*================================================
Sidebar Widget Area CSS
=================================================*/
.widget-area .widget {
  margin-top: 35px;
}

.widget-area .widget:first-child {
  margin-top: 0;
}

.widget-area .widget .widget-title {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 25px;
  text-transform: capitalize;
  position: relative;
  font-size: 22px;
}

.widget-area .widget .widget-title::before {
  content: '';
  position: absolute;
  background: #209e2e;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.widget-area .widget_search {
  -webkit-box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  background-color: #ffffff;
  padding: 15px;
}

.widget-area .widget_search form {
  position: relative;
}

.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}

.widget-area .widget_search form .screen-reader-text {
  display: none;
}

.widget-area .widget_search form .search-field {
  background-color: transparent;
  height: 50px;
  padding: 6px 15px;
  border: 1px solid #eeeeee;
  width: 100%;
  display: block;
  outline: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.widget-area .widget_search form .search-field:focus {
  border-color: #209e2e;
}

.widget-area .widget_search form button {
  position: absolute;
  right: 0;
  outline: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  z-index: 1;
  border: none;
  color: #ffffff;
  background-color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.widget-area .widget_search form button:hover {
  background-color: #333333;
  color: #ffffff;
}

.widget-area .widget_abaris_posts_thumb {
  position: relative;
  overflow: hidden;
}

.widget-area .widget_abaris_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}

.widget-area .widget_abaris_posts_thumb .item:last-child {
  margin-bottom: 0;
}

.widget-area .widget_abaris_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 80px;
  margin-right: 15px;
}

.widget-area .widget_abaris_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}

.widget-area .widget_abaris_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(../../assets/img/blog-details/1.jpg);
}

.widget-area .widget_abaris_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(../../assets/img/blog-details/2.jpg);
}

.widget-area .widget_abaris_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(../../assets/img/blog-details/3.jpg);
}

.widget-area .widget_abaris_posts_thumb .item .info {
  overflow: hidden;
  margin-top: 5px;
}

.widget-area .widget_abaris_posts_thumb .item .info time {
  display: block;
  color: #7a7e9a;
  text-transform: capitalize;
  margin-top: -2px;
  margin-bottom: 3px;
  font-size: 14px;
}

.widget-area .widget_abaris_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 18px;
}

.widget-area .widget_abaris_posts_thumb .item .info .title a {
  display: inline-block;
}

.widget-area .widget_recent_entries ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_recent_entries ul li {
  position: relative;
  margin-bottom: 12px;
  color: #333333;
  padding-left: 14px;
  line-height: 1.5;
  font-weight: 400;
  font-size: 15.5px;
}

.widget-area .widget_recent_entries ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_recent_entries ul li::before {
  background: #209e2e;
  position: absolute;
  height: 7px;
  width: 7px;
  content: '';
  left: 0;
  top: 8px;
}

.widget-area .widget_recent_entries ul li a {
  display: inline-block;
  color: #333333;
}

.widget-area .widget_recent_entries ul li a:hover {
  color: #209e2e;
}

.widget-area .widget_recent_entries ul li .post-date {
  display: block;
  font-size: 13px;
  color: #7a7e9a;
  margin-top: 4px;
}

.widget-area .widget_recent_comments ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_recent_comments ul li {
  position: relative;
  margin-bottom: 12px;
  color: #7a7e9a;
  padding-left: 14px;
  line-height: 1.5;
  font-weight: 400;
  font-size: 15.5px;
}

.widget-area .widget_recent_comments ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_recent_comments ul li::before {
  background: #209e2e;
  position: absolute;
  height: 7px;
  width: 7px;
  content: '';
  left: 0;
  top: 8px;
}

.widget-area .widget_recent_comments ul li a {
  display: inline-block;
  color: #333333;
}

.widget-area .widget_recent_comments ul li a:hover {
  color: #209e2e;
}

.widget-area .widget_archive ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_archive ul li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 14px;
  color: #333333;
  font-size: 15.5px;
  font-weight: 400;
}

.widget-area .widget_archive ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_archive ul li::before {
  background: #209e2e;
  height: 7px;
  width: 7px;
  content: '';
  left: 0;
  top: 8px;
  position: absolute;
}

.widget-area .widget_archive ul li a {
  display: inline-block;
  color: #333333;
}

.widget-area .widget_archive ul li a:hover {
  color: #209e2e;
}

.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  color: #7a7e9a;
  padding-left: 14px;
  font-size: 15.5px;
  font-weight: 400;
}

.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_categories ul li::before {
  background: #209e2e;
  height: 7px;
  width: 7px;
  content: '';
  left: 0;
  top: 8px;
  position: absolute;
}

.widget-area .widget_categories ul li a {
  color: #333333;
  display: block;
}

.widget-area .widget_categories ul li a:hover {
  color: #209e2e;
}

.widget-area .widget_categories ul li .post-count {
  float: right;
}

.widget-area .widget_meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_meta ul li {
  position: relative;
  margin-bottom: 12px;
  color: #333333;
  padding-left: 14px;
  font-size: 15.5px;
  font-weight: 400;
}

.widget-area .widget_meta ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_meta ul li::before {
  background: #209e2e;
  height: 7px;
  width: 7px;
  content: '';
  left: 0;
  top: 8px;
  position: absolute;
}

.widget-area .widget_meta ul li a {
  display: inline-block;
  color: #333333;
}

.widget-area .widget_meta ul li a:hover {
  color: #209e2e;
}

.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 12px;
}

.widget-area .tagcloud a {
  display: inline-block;
  color: #333333;
  font-weight: 400;
  font-size: 14.5px !important;
  padding: 6px 13px;
  border: 1px dashed #eeeeee;
  margin-top: 8px;
  margin-right: 4px;
}

.widget-area .tagcloud a:hover,
.widget-area .tagcloud a:focus {
  color: #ffffff;
  background-color: #209e2e;
  border-color: #209e2e;
}

.widget-area .widget_event_details ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_event_details ul li {
  border-bottom: 1px solid #eeeeee;
  color: #888f96;
  padding-bottom: 10px;
  padding-top: 10px;
}

.widget-area .widget_event_details ul li:first-child {
  padding-top: 0;
}

.widget-area .widget_event_details ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.widget-area .widget_event_details ul li span {
  display: inline-block;
  color: #333333;
  font-weight: 400;
}

.widget-area .widget_event_details ul li a {
  display: inline-block;
  color: #888f96;
}

.widget-area .widget_event_details ul li a:hover {
  color: #209e2e;
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc .article-content {
  margin-top: 30px;
}

.blog-details-desc .article-content .entry-meta {
  margin-bottom: -8px;
}

.blog-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.blog-details-desc .article-content .entry-meta ul li {
  position: relative;
  display: inline-block;
  color: #333333;
  margin-right: 21px;
}

.blog-details-desc .article-content .entry-meta ul li span {
  display: inline-block;
  color: #333333;
  font-weight: 500;
}

.blog-details-desc .article-content .entry-meta ul li a {
  display: inline-block;
  color: #7a7e9a;
}

.blog-details-desc .article-content .entry-meta ul li a:hover {
  color: #209e2e;
}

.blog-details-desc .article-content .entry-meta ul li i {
  color: #209e2e;
  margin-right: 2px;
}

.blog-details-desc .article-content .entry-meta ul li::before {
  content: '';
  position: absolute;
  top: 12px;
  right: -15px;
  width: 6px;
  height: 1px;
  background: #209e2e;
}

.blog-details-desc .article-content .entry-meta ul li:last-child {
  margin-right: 0;
}

.blog-details-desc .article-content .entry-meta ul li:last-child::before {
  display: none;
}

.blog-details-desc .article-content h3 {
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 20px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li {
  -ms-flex: 0 0 33.3333%;
  -webkit-box-flex: 0;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}

.blog-details-desc .article-content .features-list {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 30px;
}

.blog-details-desc .article-content .features-list li {
  margin-bottom: 16px;
  position: relative;
  padding-left: 34px;
  color: #7a7e9a;
}

.blog-details-desc .article-content .features-list li i {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 40px;
  background-color: #faf5f5;
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  display: inline-block;
  font-size: 11px;
  position: absolute;
  left: 0;
  top: -2px;
}

.blog-details-desc .article-content .features-list li:hover i {
  background-color: #209e2e;
  color: #ffffff;
}

.blog-details-desc .article-content .features-list li:last-child {
  margin-bottom: 0;
}

.blog-details-desc .article-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
}

.blog-details-desc .article-footer .article-tags {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  color: #7a7e9a;
}

.blog-details-desc .article-footer .article-tags span {
  display: inline-block;
  color: #333333;
  font-size: 20px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.blog-details-desc .article-footer .article-tags a {
  display: inline-block;
  color: #7a7e9a;
}

.blog-details-desc .article-footer .article-tags a:hover {
  color: #209e2e;
}

.blog-details-desc .article-footer .article-share {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.blog-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}

.blog-details-desc .article-footer .article-share .social li {
  display: inline-block;
}

.blog-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 2px;
  font-weight: 500;
  color: #7a7e9a;
}

.blog-details-desc .article-footer .article-share .social li a {
  display: block;
  color: #209e2e;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: #eeeef0;
  text-align: center;
  font-size: 12px;
}

.blog-details-desc .article-footer .article-share .social li a:hover {
  color: #ffffff;
  background-color: #209e2e;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.blog-details-desc .post-navigation {
  margin-top: 30px;
}

blockquote,
.blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
}

blockquote p,
.blockquote p {
  color: #333333;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 24px !important;
}

blockquote cite,
.blockquote cite {
  display: none;
}

blockquote::before,
.blockquote::before {
  color: #efefef;
  content: "\f10e";
  position: absolute;
  left: 50px;
  top: -50px;
  z-index: -1;
  font-family: "Font Awesome 5 Free";
  font-size: 140px;
  font-weight: 900;
}

blockquote::after,
.blockquote::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #209e2e;
  margin-top: 20px;
  margin-bottom: 20px;
}

.post-navigation {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}

.post-navigation .navigation-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.post-navigation .navigation-links .nav-previous {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.post-navigation .navigation-links .nav-previous a i {
  margin-right: 2px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.post-navigation .navigation-links .nav-previous a:hover i {
  margin-right: 0;
}

.post-navigation .navigation-links .nav-next {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
}

.post-navigation .navigation-links .nav-next a i {
  margin-left: 2px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.post-navigation .navigation-links .nav-next a:hover i {
  margin-left: 0;
}

.post-navigation .navigation-links div a {
  display: inline-block;
  font-weight: 400;
}

.comments-area {
  padding: 25px;
  margin-top: 30px;
  -webkit-box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background-color: #ffffff;
}

.comments-area .comments-title {
  position: relative;
  margin-bottom: 30px;
  line-height: initial;
  font-size: 24px;
  font-weight: 600;
}

.comments-area ol,
.comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .comment-body {
  border-bottom: 1px solid #eeeeee;
  padding-left: 65px;
  color: #333333;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.comments-area .comment-body .reply {
  margin-top: 15px;
}

.comments-area .comment-body .reply a {
  border: 1px solid #ded9d9;
  color: #333333;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  font-size: 12px;
  font-weight: 400;
}

.comments-area .comment-body .reply a:hover {
  color: #ffffff;
  background-color: #209e2e;
  border-color: #209e2e;
}

.comments-area .comment-author {
  font-size: 17px;
  margin-bottom: 0.1em;
  position: relative;
  z-index: 2;
}

.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
}

.comments-area .comment-author .fn {
  font-weight: 500;
}

.comments-area .comment-author .says {
  display: none;
}

.comments-area .comment-metadata {
  margin-bottom: .8em;
  color: #7a7e9a;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
}

.comments-area .comment-metadata a {
  color: #7a7e9a;
}

.comments-area .comment-metadata a:hover {
  color: #209e2e;
}

.comments-area .comment-respond {
  margin-top: 30px;
}

.comments-area .comment-respond .comment-reply-title {
  margin-bottom: 0;
  position: relative;
  font-size: 24px;
  font-weight: 600;
}

.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  display: inline-block;
}

.comments-area .comment-respond .comment-form {
  overflow: hidden;
}

.comments-area .comment-respond .comment-notes {
  margin-bottom: 0;
  margin-top: 10px;
}

.comments-area .comment-respond .comment-form-comment {
  margin-top: 15px;
  float: left;
  width: 100%;
}

.comments-area .comment-respond label {
  display: block;
  font-weight: 400;
  color: #333333;
  margin-bottom: 5px;
}

.comments-area .comment-respond input[type="date"],
.comments-area .comment-respond input[type="time"],
.comments-area .comment-respond input[type="datetime-local"],
.comments-area .comment-respond input[type="week"],
.comments-area .comment-respond input[type="month"],
.comments-area .comment-respond input[type="text"],
.comments-area .comment-respond input[type="email"],
.comments-area .comment-respond input[type="url"],
.comments-area .comment-respond input[type="password"],
.comments-area .comment-respond input[type="search"],
.comments-area .comment-respond input[type="tel"],
.comments-area .comment-respond input[type="number"],
.comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.comments-area .comment-respond input[type="date"]:focus,
.comments-area .comment-respond input[type="time"]:focus,
.comments-area .comment-respond input[type="datetime-local"]:focus,
.comments-area .comment-respond input[type="week"]:focus,
.comments-area .comment-respond input[type="month"]:focus,
.comments-area .comment-respond input[type="text"]:focus,
.comments-area .comment-respond input[type="email"]:focus,
.comments-area .comment-respond input[type="url"]:focus,
.comments-area .comment-respond input[type="password"]:focus,
.comments-area .comment-respond input[type="search"]:focus,
.comments-area .comment-respond input[type="tel"]:focus,
.comments-area .comment-respond input[type="number"]:focus,
.comments-area .comment-respond textarea:focus {
  border-color: #209e2e;
}

.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 4px;
}

.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: #7a7e9a;
  font-weight: normal;
  position: relative;
  top: -2px;
}

.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}

.comments-area .comment-respond .form-submit input {
  background: #209e2e;
  border: none;
  color: #ffffff;
  padding: 10px 30px;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border-radius: 0;
  text-transform: capitalize;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  font-weight: 400;
  font-size: 14px;
}

.comments-area .comment-respond .form-submit input:hover,
.comments-area .comment-respond .form-submit input:focus {
  color: #ffffff;
  background-color: #333333;
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
  margin-top: 20px;
  text-align: center;
}

.pagination-area .page-numbers {
  width: 45px;
  height: 45px;
  margin: 0 3px;
  display: inline-block;
  background-color: #ffffff;
  line-height: 48px;
  color: #333333;
  -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
  box-shadow: 0 2px 10px 0 #d8dde6;
  font-size: 18px;
  font-weight: 700;
}

.pagination-area .page-numbers.current,
.pagination-area .page-numbers:hover,
.pagination-area .page-numbers:focus {
  background: #209e2e;
  color: #ffffff;
  -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
  box-shadow: 0 2px 10px 0 #d8dde6;
}

/*================================================
Product Details Area CSS
=================================================*/
.downloadBtn {
  background: #2f4ad0;
  box-shadow: 0 8px 6px -6px #555;
  transition: all 0.4s ease;
  padding: 8px 16px;
  color: #fff;
  border-radius: 4px;
}

.downloadBtn:hover {
  color: #fff;
  transform: translateY(-4px);
}

.downloadBtn svg {
  font-size: 22px;
}

.product-details-image .largeImg div div {
  position: relative;
  z-index: 1;
  border: 0;
}

.product-details-image>div {
  text-align: center;
}

.product-details-image>div>img {
  object-fit: contain;
}

.product-details-desc h3 {
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: 700;
}

.product-details-desc .price {
  color: #000000;
  font-size: 19px;
  font-weight: 600;
  display: flex;
  column-gap: 10px;
}

.product-details-desc .allTaxes {
  font-size: 12px;
}

.product-details-desc .price .old-price {
  text-decoration: line-through;
  color: #828893;
}

.product-details-desc .product-review {
  margin-bottom: 15px;
}

.product-details-desc .product-review .rating {
  display: inline-block;
  padding-right: 5px;
  font-size: 14px;
}

.product-details-desc .product-review .rating i,
.product-details-desc .product-review .rating svg {
  color: #ffba0a;
}

.product-details-desc .product-review .rating-count {
  display: inline-block;
  color: #000000;
  border-bottom: 1px solid #000000;
  line-height: initial;
}

.product-details-desc .product-review .rating-count:hover {
  color: #209e2e;
  border-color: #209e2e;
  text-decoration: none;
}

.product-details-desc .product-add-to-cart {
  margin-top: 20px;
  display: flex;
}

.product-details-desc .product-add-to-cart .input-counter {
  max-width: 130px;
  min-width: 130px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.product-details-desc .product-add-to-cart .input-counter span {
  position: absolute;
  top: -4px;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 50px;
  height: 100%;
  line-height: 55px;
  -webkit-transition: .6s;
  transition: .6s;
}

.product-details-desc .product-add-to-cart .input-counter span.minus-btn {
  left: 0;
}

.product-details-desc .product-add-to-cart .input-counter span.plus-btn {
  right: 0;
}

.product-details-desc .product-add-to-cart .input-counter span:hover {
  color: #209e2e;
}

.product-details-desc .product-add-to-cart .input-counter input {
  height: 50px;
  color: #000000;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}

.product-details-desc .product-add-to-cart .input-counter input::-webkit-input-placeholder {
  color: #000000;
}

.product-details-desc .product-add-to-cart .input-counter input:-ms-input-placeholder {
  color: #000000;
}

.product-details-desc .product-add-to-cart .input-counter input::-ms-input-placeholder {
  color: #000000;
}

.product-details-desc .product-add-to-cart .input-counter input::placeholder {
  color: #000000;
}

.product-details-desc .product-add-to-cart .default-btn {
  position: relative;
  border: none;
  padding: 12px 30px;
  background-color: #ff1e00;
  color: #ffffff;
  border: 1px solid #209e2e;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.product-details-desc .product-add-to-cart .default-btn i {
  margin-right: 2px;
}

.product-details-desc .product-add-to-cart .default-btn:hover {
  background-color: #ffffff;
  color: #209e2e;
  -webkit-transition: .6s;
  transition: .6s;
}

.product-details-desc .buy-checkbox-btn {
  margin-top: 20px;
}

.product-details-desc .buy-checkbox-btn input {
  display: none;
}

.product-details-desc .buy-checkbox-btn .cbx {
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.product-details-desc .buy-checkbox-btn .cbx span {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.product-details-desc .buy-checkbox-btn .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  -webkit-transform: scale(1);
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #ebebeb;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transition: .6s;
  transition: .6s;
}

.product-details-desc .buy-checkbox-btn .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: .6s;
  transition: .6s;
}

.product-details-desc .buy-checkbox-btn .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #000000;
  display: block;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
  -webkit-transition: .6s;
  transition: .6s;
}

.product-details-desc .buy-checkbox-btn .cbx span:last-child {
  position: relative;
  top: 1px;
  padding-left: 4px;
  color: #666666;
}

.product-details-desc .buy-checkbox-btn .cbx:hover span:first-child {
  border-color: #209e2e;
}

.product-details-desc .buy-checkbox-btn .inp-cbx:checked+.cbx span:first-child {
  background: #209e2e;
  border-color: #209e2e;
  -webkit-animation: wave 0.4s ease;
  animation: wave 0.4s ease;
}

.product-details-desc .buy-checkbox-btn .inp-cbx:checked+.cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.product-details-desc .buy-checkbox-btn .inp-cbx:checked+.cbx span:first-child:before {
  -webkit-transform: scale(3.5);
  transform: scale(3.5);
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.product-details-desc .buy-checkbox-btn .item:not(:first-child) {
  margin-top: 20px;
}

.product-details-desc .buy-checkbox-btn .btn-light {
  background-color: #f2f2f2;
  border: none;
  padding: 13px 25px 10px 25px;
  -webkit-transition: .6s;
  transition: .6s;
  font-weight: 600;
  display: block;
  width: 100%;
}

.product-details-desc .buy-checkbox-btn .btn-light:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.product-details-desc .custom-payment-options {
  margin-top: 20px;
}

.product-details-desc .custom-payment-options span {
  display: block;
  color: #666666;
  margin-bottom: 8px;
}

.product-details-desc .custom-payment-options .payment-methods {
  display: flex;
  column-gap: 4px;
  align-items: center;
}

.product-details-desc .custom-payment-options .payment-methods a img {
  width: 40px;
}

.products-details-tab {
  margin-top: 50px;
}

.products-details-tab .tabs {
  list-style-type: none;
  margin-bottom: -1px;
  padding-left: 0;
}

.products-details-tab .tabs li {
  display: inline-block;
  line-height: initial;
  margin-right: 5px;
  margin-bottom: 5px;
}

.products-details-tab .tabs li a {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  color: #333333;
  background-color: #eeeeee;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 30px;
  padding-left: 30px;
  font-weight: 700;
  font-size: 15px;
}

.products-details-tab .tabs li a:hover,
.products-details-tab .tabs li a:focus {
  color: #ffffff;
  background-color: #209e2e;
  border-color: #209e2e;
}

.products-details-tab .tabs li.current a {
  color: #ffffff;
  background-color: #209e2e;
  border-color: #209e2e;
}

.products-details-tab .tabs li a.nav-link.active {
  color: #ffffff;
  background-color: #209e2e;
  border-color: #209e2e;
}

.products-details-tab .tabs li:last-child {
  margin-right: 0;
}

.products-details-tab .tab_content {
  border: 1px solid #eeeeee;
  padding: 30px;
}

.products-details-tab .tab_content .tabs_item {
  display: none;
}

.products-details-tab .tab_content .tabs_item:first-child {
  display: block;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content p {
  margin-bottom: 20px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content p:last-child {
  margin-bottom: 0;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  text-align: left;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li {
  border: 1px solid #eeeeee;
  border-bottom: none;
  padding: 10px 15px 7px;
  color: #666666;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li:last-child {
  border-bottom: 1px solid #eeeeee;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li span {
  display: inline-block;
  width: 30%;
  color: #333333;
  font-weight: 500;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content h3 {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 500;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title {
  position: relative;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating {
  display: inline-block;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating .fas.fa-star {
  color: #ffba0a;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating i {
  color: #ebebeb;
  font-size: 14px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title p {
  margin-bottom: 0;
  display: inline-block;
  padding-left: 5px;
  line-height: initial;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .default-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px 25px;
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
  -webkit-transition: .6s;
  transition: .6s;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .default-btn:hover {
  background-color: #ffffff;
  color: #209e2e;
  text-decoration: none;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments {
  margin-top: 35px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item {
  margin-top: 30px;
  position: relative;
  padding-right: 200px;
  border-top: 1px dashed #eeeeee;
  padding-top: 30px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating .fas.fa-star {
  color: #ffba0a;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating i {
  font-size: 14px;
  color: #ebebeb;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item h3 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span {
  margin-bottom: 10px;
  font-size: 14px;
  display: block;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span strong {
  font-weight: 400;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item p {
  margin-bottom: 0;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link {
  position: absolute;
  right: 0;
  color: #666666;
  top: 40px;
  text-decoration: underline;
  font-weight: 400;
  font-size: 15px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link:hover {
  color: #209e2e;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form {
  margin-top: 30px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form h3 {
  margin-bottom: 20px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form {
  max-width: 100%;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group {
  margin-bottom: 15px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group .form-control {
  height: 55px;
  border-radius: 0;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group .form-control:focus {
  box-shadow: none;
  border-color: #ff8f00;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group textarea.form-control {
  padding-top: 15px;
  height: auto;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .default-btn {
  border: none;
  margin-top: 10px;
  -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  padding: 14px 25px;
  background-color: #209e2e;
  color: #ffffff;
  border: 1px solid #209e2e;
  text-transform: uppercase;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .default-btn:hover {
  background-color: #ffffff;
  color: #209e2e;
  -webkit-transition: .6s;
  transition: .6s;
}

.related-products {
  margin-top: 70px;
  text-align: center;
}

.related-products .products-title {
  margin-bottom: 10px;
  text-align: center;
}

.related-products .products-title span {
  font-size: 14px;
  color: #209e2e;
}

.related-products .products-title h2 {
  font-size: 30px;
  color: #333333;
  margin: 10px 0 0 0;
}

.related-products .single-product-box {
  margin-bottom: 0;
  margin-top: 30px;
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table table {
  margin-bottom: 0;
}

.cart-table table thead {
  text-align: center;
}

.cart-table table thead tr th {
  border-bottom-width: 0px;
  vertical-align: middle;
  padding: 25px 0 25px;
  text-transform: uppercase;
  border: none;
  font-weight: 500;
  font-size: 18px;
}

.cart-table table tbody tr td {
  vertical-align: middle;
  color: #666666;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  border-color: #eaedff;
  border-left: none;
  border-right: none;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
}

.cart-table table tbody tr td.product-thumbnail img {
  width: 60px;
  height: 60px;
}

.cart-table table tbody tr td.product-thumbnail a {
  display: block;
}

.cart-table table tbody tr td.product-name a {
  color: #666666;
  font-weight: 500;
  display: inline-block;
  font-size: 16px;
}

.cart-table table tbody tr td.product-name a:hover {
  color: #333333;
}

.cart-table table tbody tr td.product-price span {
  font-weight: 500;
  font-size: 16px;
}

.cart-table table tbody tr td.product-subtotal {
  overflow: hidden;
}

.cart-table table tbody tr td.product-subtotal .remove {
  color: #666666;
  float: right;
  position: relative;
  top: 1px;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}

.cart-table table tbody tr td.product-subtotal .remove i {
  font-size: 16px;
}

.cart-table table tbody tr td.product-subtotal .remove:hover {
  color: red;
}

.cart-table table tbody tr td.product-subtotal span {
  font-weight: 500;
  font-size: 16px;
}

.cart-table table tbody tr td.product-quantity .input-counter {
  max-width: 130px;
  min-width: 130px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.cart-table table tbody tr td.product-quantity .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 40px;
  height: 100%;
  line-height: 48px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.cart-table table tbody tr td.product-quantity .input-counter span:hover {
  color: #333333;
}

.cart-table table tbody tr td.product-quantity .input-counter span.minus-btn {
  left: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span.plus-btn {
  right: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter input {
  height: 45px;
  color: #333333;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-webkit-input-placeholder {
  color: #333333;
}

.cart-table table tbody tr td.product-quantity .input-counter input:-ms-input-placeholder {
  color: #333333;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-ms-input-placeholder {
  color: #333333;
}

.cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
  color: #333333;
}

.cart-buttons {
  margin-top: 30px;
  text-align: center;
}

.cart-totals {
  background: #ffffff;
  padding: 40px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 0;
  margin: auto;
  margin-top: 50px;
}

.cart-totals h3 {
  margin-bottom: 20px;
  font-size: 20px;
}

.cart-totals ul {
  padding: 0;
  margin: 0 0 25px;
  list-style-type: none;
}

.cart-totals ul li {
  border: 1px solid #eaedff;
  padding: 12px 15px;
  color: #323232;
  overflow: hidden;
  font-weight: 500;
}

.cart-totals ul li:first-child {
  border-bottom: none;
}

.cart-totals ul li:last-child {
  border-top: none;
}

.cart-totals ul li span {
  float: right;
  color: #666666;
  font-weight: normal;
}

.cart-totals .default-btn {
  width: 100%;
}

.cart-area .coupon-wrap {
  margin-top: 50px;
  background-color: #ffffff;
  padding: 40px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.cart-area .coupon-wrap h3 {
  padding-bottom: 25px;
  font-size: 20px;
}

.cart-area .coupon-wrap .shops-form {
  text-align: center;
}

.cart-area .coupon-wrap .shops-form .form-group {
  margin-bottom: 15px;
}

.cart-area .coupon-wrap .shops-form .form-group .form-control {
  height: 55px;
  color: #6e6e6e;
  border: 1px solid #e8e8e8;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  font-size: 15px;
  padding: 10px 20px;
  width: 100%;
}

.cart-area .coupon-wrap .shops-form .form-group .form-control:focus {
  border-color: #209e2e;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cart-area .coupon-wrap .shops-form .nice-select {
  height: 55px;
  line-height: 49px;
  border-radius: 0;
  width: 100%;
  margin-bottom: 15px;
}

.cart-area .coupon-wrap .shops-form .nice-select.open .list {
  width: 100%;
}

.cart-area .coupon-wrap .shops-form .default-btn {
  width: 100%;
}

.cart-wraps {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  padding: 40px;
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 18px 20px 15px;
  margin-bottom: 65px;
  border-top: 3px solid #333333;
  position: relative;
}

.user-actions::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: -35px;
  width: 100%;
  height: 1px;
  background: #eeeeee;
}

.user-actions i {
  color: #333333;
  margin-right: 2px;
}

.user-actions span {
  display: inline-block;
  font-weight: 400;
  color: #333333;
  font-size: 16px;
}

.user-actions span a {
  display: inline-block;
  color: #333333;
  text-decoration: none;
}

.user-actions span a:hover,
.user-actions span a:focus {
  color: #209e2e;
}

.billing-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  font-size: 24px;
  font-weight: 700;
}

.billing-details .title::before {
  content: '';
  position: absolute;
  background: #209e2e;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.billing-details .form-group {
  margin-bottom: 25px;
}

.billing-details .form-group label {
  display: block;
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: 400;
}

.billing-details .form-group label .required {
  color: #333333;
}

.billing-details .form-group .nice-select {
  float: unset;
  line-height: 53px;
  color: #666666;
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0;
}

.billing-details .form-group .nice-select .list {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  border-radius: 0;
  margin-top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.billing-details .form-group .nice-select .list .option {
  -webkit-transition: .6s;
  transition: .6s;
  padding-left: 20px;
  padding-right: 20px;
}

.billing-details .form-group .nice-select .list .option:hover {
  background-color: #209e2e !important;
  color: #ffffff;
}

.billing-details .form-group .nice-select .list .option.selected {
  background-color: transparent;
  font-weight: 600;
}

.billing-details .form-group .nice-select:after {
  right: 20px;
}

.billing-details .form-group .form-control {
  height: 50px;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.billing-details .form-group .form-control:focus {
  border-color: #209e2e;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.billing-details .form-group textarea.form-control {
  height: auto;
  padding-top: 15px;
}

.billing-details .form-check {
  margin-bottom: 20px;
}

.billing-details .form-check .form-check-label {
  color: #333333;
}

.billing-details .form-check label {
  position: relative;
  left: -3px;
  top: 1px;
  font-weight: 500;
}

.billing-details .col-lg-12:last-child .form-group {
  margin-bottom: 0;
}

.order-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  font-size: 24px;
  font-weight: 700;
}

.order-details .title::before {
  content: '';
  position: absolute;
  background: #209e2e;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.order-details .order-table table {
  margin-bottom: 0;
}

.order-details .order-table table thead tr th {
  border-bottom-width: 0;
  vertical-align: middle;
  border-color: #eaedff;
  text-transform: uppercase;
  padding-left: 20px;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 10px;
  font-weight: 600;
}

.order-details .order-table table tbody tr td {
  vertical-align: middle;
  color: #666666;
  border-color: #eaedff;
  font-size: 15.5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 13px;
}

.order-details .order-table table tbody tr td.product-name a {
  color: #666666;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

.order-details .order-table table tbody tr td.product-name a:hover {
  color: #209e2e;
}

.order-details .order-table table tbody tr td.order-subtotal span,
.order-details .order-table table tbody tr td.order-shipping span,
.order-details .order-table table tbody tr td.total-price span {
  color: #666666;
  font-weight: 400;
}

.order-details .order-table table tbody tr td.shipping-price,
.order-details .order-table table tbody tr td.order-subtotal-price,
.order-details .order-table table tbody tr td.product-subtotal {
  font-weight: 400;
}

.order-details .payment-box {
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  margin-top: 30px;
  padding: 30px;
}

.order-details .payment-box .payment-method p [type="radio"]:checked,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked) {
  display: none;
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  color: #333333;
  position: relative;
  margin-bottom: 8px;
  font-weight: 600;
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label::before,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: #ffffff;
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label::after,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label::after {
  content: '';
  width: 12px;
  height: 12px;
  background: #209e2e;
  position: absolute;
  top: 6px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: .6s;
  transition: .6s;
}

.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label::after {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.order-details .payment-box .default-btn {
  padding: 14px 30px;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  background-color: #ffffff;
  -webkit-transition: .6s;
  transition: .6s;
}

.order-details .payment-box .default-btn:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.order-details .payment-box .default-btn.order-btn {
  display: block;
  margin-top: 25px;
  -webkit-box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}

/*================================================
Privacy Section CSS
=================================================*/
.single-privacy h3 {
  font-size: 25px;
  margin: 0 0 12px 0;
}

/*================================================
Footer Section CSS
=================================================*/
.footer-section {
  padding: 40px 0 10px;
}

.footer-section.footer-bg {
  background-color: #070707;
  opacity: .8;
}


.footer-section.footer-bg .single-footer-widget p {
  color: #ccc;
}

.footer-section.footer-bg .single-footer-widget .footer-social a {
  background-color: #ffffff;
  color: #f5f5f5;
}

.footer-section.footer-bg .single-footer-widget .footer-social a:hover {
  background-color: #209e2e;
  color: #f5f5f5;
}

.footer-section.footer-bg .single-footer-widget .footer-heading h3 {
  color: #fff;
}

.footer-section.footer-bg .single-footer-widget .footer-quick-links li {
  color: #fff;
}

.footer-section.footer-bg .single-footer-widget .footer-quick-links li a {
  color: #ccc;
}

.footer-section.footer-bg .single-footer-widget .footer-quick-links li a:hover {
  color: #ffa90f;
}

.footer-section.footer-bg .single-footer-widget .footer-info-contact i {
  color: #b48017;
}

.footer-section.footer-bg .single-footer-widget .footer-info-contact h3 {
  color: #fff;
}

.footer-section.footer-bg .single-footer-widget .footer-info-contact span {
  color: #ccc;
}

.footer-section.footer-bg .single-footer-widget .footer-info-contact span a {
  color: #ccc;
}

.footer-section.footer-bg .single-footer-widget .footer-info-contact span a:hover {
  color: #ffa90f;
}

.footer-section.footer-bg .single-footer-widget .footer-map {
  padding-left: 0;
  margin-bottom: 0;
}

.footer-section.footer-bg .single-footer-widget .footer-map li {
  list-style-type: none;
  font-size: 18px;
  font-weight: 500;
}

.footer-section.footer-bg .single-footer-widget .footer-map a {
  color: #209e2e;
}

.footer-section.footer-bg .copyright-area .copyright-area-content p {
  color: #ccc;
}

.footer-section.footer-bg .copyright-area .copyright-area-content p a {
  color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.footer-section.footer-bg .copyright-area .copyright-area-content p a:hover {
  color: #209e2e;
}

.footer-section.footer-bg .copyright-area .copyright-area-content ul li {
  color: #000;
}

.footer-section.footer-bg .copyright-area .copyright-area-content ul li a {
  color: #ccc;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.footer-section.footer-bg .copyright-area .copyright-area-content ul li a:hover {
  color: #209e2e;
}

.single-footer-widget {
  margin-bottom: 20px;
}

.single-footer-widget p {
  color: #7a7e9a;
  margin: 0 0 18px 0;
  line-height: 25px;
}

.single-footer-widget .footer-social {
  padding-left: 0;
  margin-bottom: 0;
}

.single-footer-widget .footer-social li {
  list-style-type: none;
  display: inline-block;
}

.single-footer-widget .footer-social a,
.sellerSocial .footer-social a {
  display: inline-block;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d2ecd5;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  border-radius: 30px;
  margin-right: 5px;
}

.sellerSocial .footer-social a.facebook {
  background-color: #3c579e;
}

.sellerSocial .footer-social a.twitter {
  background-color: #1cb8ec;
}

.sellerSocial .footer-social a.instagram {
  background-color: #ec0a68;
}

.sellerSocial .footer-social a.whatsapp {
  background-color: #1bd741;
}

.single-footer-widget .footer-social a:hover,
.sellerSocial .footer-social a:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.single-footer-widget .footer-heading {
  margin-bottom: 20px;
}

.single-footer-widget .footer-heading h3 {
  font-size: 15px;
  font-weight: 700;
  color: #209e2e;
  margin: 0;
  text-transform: uppercase;
}

.single-footer-widget .footer-quick-links {
  padding-left: 0;
  margin-bottom: 0;
}

.single-footer-widget .footer-quick-links li {
  list-style-type: none;
  padding-bottom: 8px;
  color: #7a7e9a;
}

.single-footer-widget .footer-quick-links li:last-child {
  padding-bottom: 0;
}

.single-footer-widget .footer-quick-links li a {
  display: inline-block;
  color: #7a7e9a;
  font-size: 15px;
  font-weight: 400;
}

.single-footer-widget .footer-quick-links li a:hover {
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-footer-widget .footer-info-contact {
  position: relative;
  margin-bottom: 16px;
}

.single-footer-widget .footer-info-contact:last-child {
  margin-bottom: 0;
}

.single-footer-widget .footer-info-contact i {
  color: #209e2e;
  position: absolute;
  left: 0;
  top: -6px;
  font-size: 16px;
}

.single-footer-widget .footer-info-contact h3 {
  font-size: 14px;
  color: #209e2e;
  font-weight: 400;
  margin: 0 0 10px 0;
  padding-left: 25px;
  text-transform: uppercase;
}

.single-footer-widget .footer-info-contact span {
  font-size: 14px;
  color: #7a7e9a;
  font-weight: 400;
}

.single-footer-widget .footer-info-contact span a {
  font-size: 14px;
  color: #7a7e9a;
  font-weight: 400;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-footer-widget .footer-info-contact span a:hover {
  color: #209e2e;
}

.single-footer-widget .newsletter-form {
  max-width: 580px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.single-footer-widget .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  background-color: #f5f5f5;
  border: none;
  height: 50px;
  padding-left: 25px;
  border-radius: 0;
  outline: 0;
  color: #333333;
  font-size: 15px;
}

.single-footer-widget .newsletter-form .input-newsletter::-webkit-input-placeholder {
  color: #7a7e9a;
}

.single-footer-widget .newsletter-form .input-newsletter:-ms-input-placeholder {
  color: #7a7e9a;
}

.single-footer-widget .newsletter-form .input-newsletter::-ms-input-placeholder {
  color: #7a7e9a;
}

.single-footer-widget .newsletter-form .input-newsletter::placeholder {
  color: #7a7e9a;
}

.single-footer-widget .newsletter-form button {
  position: relative;
  background-color: #ff9300;
  color: #ffffff;
  border: none;
  height: 50px;
  border-radius: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  line-height: 50px;
  text-transform: capitalize;
  width: 100%;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
}

.single-footer-widget .newsletter-form button:hover {
  background-color: #333333;
  color: #ffffff;
}

.single-footer-widget .newsletter-form button i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.single-footer-widget .newsletter-form #validator-newsletter {
  font-size: 15px;
  color: red;
  margin-top: 10px;
}

.single-footer-widget .footer-info-contact .topIcon {
  display: flex;
  /* align-items: center; */
  margin-bottom: 10px;
  column-gap: 14px;
}

.single-footer-widget .footer-info-contact .topIcon svg {
  color: #fff;
  font-size: 23px;
}

.single-footer-widget .footer-info-contact .topIcon h3 {
  margin: 0;
  padding-left: 0;
  font-weight: 600;
}

.copyright-area .copyright-area-content {
  /* padding-top: 10px;
  border-top: 1px solid #0c3220; */
}

.copyright-area .copyright-area-content p {
  color: #7a7e9a;
  font-size: 14px;
}

.copyright-area .copyright-area-content p a {
  color: #7a7e9a;
  display: inline-block;
  font-weight: 600;
}

.copyright-area .copyright-area-content p a:hover {
  color: #209e2e;
}

.copyright-area .copyright-area-content ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;

}

.copyright-area .copyright-area-content ul li {
  display: inline-block;
  color: #7a7e9a;
  font-size: 14px;
  position: relative;
  margin-left: 15px;
  margin-right: 10px;
}

.copyright-area .copyright-area-content ul li a {
  display: inline-block;
  color: #7a7e9a;
}

.copyright-area .copyright-area-content ul li a:hover {
  color: #209e2e;
}

.copyright-area .copyright-area-content ul li::before {
  content: '';
  position: absolute;
  top: 5px;
  right: -13px;
  width: 1px;
  height: 14px;
  background-color: #ffff;
}

.copyright-area .copyright-area-content ul li:last-child {
  margin-right: 0;
}

.copyright-area .copyright-area-content ul li:last-child::before {
  display: none;
}

.copyright-area .copyright-area-content ul li:first-child {
  margin-left: 0;
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 15px;
  color: #ffffff;
  background-color: #209e2e;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .9s;
  transition: .9s;
  border-radius: 50%;
}

.go-top.active {
  top: 98%;
  -webkit-transform: translateY(-98%);
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}

.go-top i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}

.go-top::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #333333;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 50%;
}

.go-top:hover,
.go-top:focus {
  color: #ffffff;
}

.go-top:hover::before,
.go-top:focus::before {
  opacity: 1;
  visibility: visible;
}

.go-top:hover i:first-child,
.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}

.go-top:hover i:last-child,
.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

/*=================================
Buy Now Btn
====================================*/
.buy-now-btn {
  right: 30px;
  z-index: 99;
  top: 50%;
  position: fixed;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 30px;
  display: inline-block;
  color: #ffffff !important;
  background-color: #82b440;
  padding: 8px 20px 8px;
  font-size: 14px;
  font-weight: 500;
  -webkit-animation-name: tada;
  animation-name: tada;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.buy-now-btn:hover {
  background-color: #209e2e;
  color: #ffffff !important;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}






/* new css */


.sub_menu_Text {
  text-transform: uppercase !important;
}

.dropAgro {
  width: 270px !important;
  /* left: 300px !important; */
  position: absolute;
}

.width-100pr {
  width: 100% !important;
}

.dropSecound {
  width: 270px !important;
  left: 360px !important;
}

.dropThird {
  width: 270px !important;
  left: 480px !important;
}

.dropFour {
  width: 270px !important;
  left: 520px !important;
}

.dropFifth {
  width: 270px !important;
  left: 580px !important;
}

.classSet>.slick-initialized {
  height: 520px;
}

.categoryItemSlider>div {
  /* overflow: hidden; */
}







.orderdetails {
  /* height: 500px; */
  padding: 20px;
  margin: 20px 0;
}

.order-detail-bill {
  display: flex;
  justify-content: space-between;
}

.bar-code {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: #dadada;
}

.fontSize {
  font-size: 18px;

}

.barSpace {
  width: 250px;
  height: 100%;
}

.barSpace>img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.userNamePro .helll {
  display: none;
  margin: 6px -7px;
  margin-top: 0;
}

.userNamePro .helll>li {
  padding: 10px;
}

.userNamePro .helll>li:hover {
  background-color: lightgray;
  /* cursor: pointer; */
}

.userNamePro .helll>li a {
  color: black;
}

.userNamePro:hover>.helll {
  display: block;

}



.imgProduct {
  height: 304px !important;
}

.todayModal {
  position: absolute;
  left: -20%;
}



/* Juniad responsive */

#exampleModalfake {
  background: #0000009e;
}

.productSize ul li button {
  padding: 10px 17px;
  background: #f7f7f7;
  color: #333;
  font-size: 15px;
  border-radius: 4px;
  border: 2px solid #f5f5f5;
}

.filterPro {
  display: none !important;
}

.crossFil {
  display: none;
}

.cartTable,
.classOverflow {
  overflow: auto !important;
}

@media only screen and (max-width: 400px) {
  .featuredImg {
    height: 290px;
  }

  .bar-code {
    display: block !important;
  }

  .optionSelect {
    display: none !important;
  }

  .productImage {
    width: 100px !important;
  }

  .crossFil {
    display: block;
  }

  .socialMedia>ul>li>a {
    font-size: 15px !important;
  }

  .optionSelect {
    /* display: initial; */
  }

  .prodcutsSec .filterPro {
    display: block !important;
    width: 100%;
    background-color: #25782e;
    color: white;
    font-weight: 600px;
  }

  .productFilterSec {
    display: none;
  }

  .featuredContent>h5 {
    height: 15px;
  }

  .sellerLists {
    display: inline-flex;
  }

  .sellerLists>li {
    margin: 0 7px;
  }

  .scroller {
    height: 163px !important;
  }

  .fisherman-content h3 {
    font-size: 18px !important;
  }

  .featuredImg>a {
    /* width: 205px !important; */
    width: 100%;
  }

  .featuredImg {
    display: flex;
    justify-content: center;
  }

  /* modal response */
  .modalFigure {
    display: block !important;
  }

  .modalFigure>.thumbnailSec>ul {
    display: flex;
    width: 270px !important;
  }

  .addToCart>button {
    padding: 10px 14px;
  }

  .addToCart>button>svg {
    display: none;
  }

  .maxWidthDialog {
    height: 600px;
    margin-top: 30px;
  }

  .maxWidthDialog>div {
    height: 100%;
  }

  .seperate {
    overflow: hidden !important;
  }

  .js-image-zoom__zoomed-image {
    display: none !important;
  }

  .classSet .exampleModal {
    background-color: black !important;
  }

  .registrationInfo {
    display: flow-root !important;
  }

  .registrationDetail {
    height: 1029px !important;
  }

  ._product-detail-content>._p-name {
    font-size: 18px;
  }

  ._p-price-box>.saveImg>img {
    width: 100% !important;
  }

  .product-add-to-cart>button {
    font-size: smaller;
  }
}

.activeBtnV {
  background: linear-gradient(45deg, #060404, #ab3c3c) !important;
  color: white !important;
}



.exampleModalPhoto {
  /* display: flex !important; */
  align-items: center;
  justify-content: center;
  background-color: #0000008c;
}

.exampleModalPhoto>div {
  width: 100%;
  margin-top: 100px;
}

.single-comment-item .sc-text button {
  display: inline-block;
  color: #19191a;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 24px;
  border: 1px solid #F9EEE3;
  border-radius: 50px;
  transition: all 0.3s;
  margin-right: 10px;
}

.activeLike {
  background-color: #00b9ff;
}

marquee>ul>li>a {
  width: 160px !important;
}

#pickupStore {
  background-color: #0000008c;
}



.fontHead>th {
  font-size: 14px;
}

.bg-grad-1 {
  background-color: #eb4786;
  background-image: linear-gradient(280deg, #eb4784 0%, #b854a6 74%);
  border-radius: 10px;
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.roundShap {
  width: 60px;
  height: 60px;
  background: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  border-radius: 100%;
  margin: auto;
  cursor: pointer;
}


.bg-grad-3 {
  background-color: #28e649;
  /* background-image: linear-gradient(315deg,#549423,#67d96d 74%); */
  background-image: linear-gradient(315deg, #ebedf2, #ebedf2 74%);
}

.wallet {
  border-radius: 4px !important;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
}

.bg-grad-3-4 {
  border-radius: 4px;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  height: 184px;
}

.fs-12-2 {
  font-size: 40px;
  color: #000;
}

.icon-recharge {
  font-size: 85px;
  color: #fff;
}

.icon-icon {
  color: #fff;
}

.table-success-2 {
  background-color: #21c265;
  color: #fff !important;
}


.bg-grad-1 {
  background-color: #eb4786;
  background-image: linear-gradient(315deg, #eb4786 0%, #b854a6 74%);
}

.dollor-icon {
  font-size: 30px;
}

.size-60px {
  height: 60px;
  width: 60px;
}

.plus {
  font-size: 46px;
  color: #fff;
}

.p-3 .text-primary-2 {
  color: black !important;
}

.bg-grad-1 {
  border-radius: 4px;
  height: 132px;
}

.hov-shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.h3-3 {
  font-size: 16px;
}

.card {
  -webkit-box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  background-color: #fff;
  margin-bottom: 20px;
  border-color: #ebedf2;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.25rem;
}

.card .card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  padding: 12px 25px;
  border-bottom: 1px solid #ebedf2;
  min-height: 50px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: transparent;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, .03);
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.card .card-body {
  padding: 20px 25px;
  border-radius: 4px;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card .card-body {
  padding: 20px 25px;
  border-radius: 4px;
}

a,
button,
input,
textarea,
.btn,
.has-transition {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.size-60px-2 {}

.text-primary {
  color: #124a2f !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.with-def {
  width: 100%;
}

.form-group {
  margin-bottom: 1rem;
}

.btn {
  padding: 0.6rem 1.2rem;
  font-size: 0.875rem;
  color: #2a3242;
  font-weight: inherit;
  background-color: #124a2f;
}

form-control[readonly] {
  background-color: #f7f8fa;
  opacity: 1;
  border-color: #e2e5ec;
}

textarea.form-control {
  height: auto;
}

.form-control {
  padding: 0.6rem 1rem;
  font-size: 0.875rem;
  height: calc(1.3125rem + 1.2rem + 2px);
  border: 1px solid #e2e5ec;
  color: #898b92;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-primary,
.btn-soft-primary:hover,
.btn-outline-primary:hover {
  background-color: #124a2f;
  border-color: #ffffff;
  color: var(--white);

}

.float-right {
  float: right;
  background-color: #124a2f !important;
  border-color: #ffffff !important;
  color: #fff !important;
}

.card {
  -webkit-box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  background-color: #fff;
  margin-bottom: 20px;
  border-color: #ebedf2;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.25rem;
}

.card .card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  padding: 12px 25px;
  border-bottom: 1px solid #ebedf2;
  min-height: 50px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: transparent;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, .03);
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.text-md-left {
  float: left !important;
}

.text-md-left {
  float: left !important;
}

.bootstrap-select>select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
  z-index: 0 !important;
}

.form-control {
  padding: 0.6rem 1rem;
  font-size: 0.875rem;
  height: calc(1.3125rem + 1.2rem + 2px);
  border: 1px solid #e2e5ec;
  color: #898b92;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.bootstrap-select .dropdown-toggle {
  color: #898b92;
  background-color: transparent !important;
  border-color: #e2e5ec;
}

.bootstrap-select>.dropdown-toggle {
  position: relative;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropdown-menu {
  border-color: #e2e5ec;
  margin: 0;
  border-radius: 0;
  min-width: 14rem;
  font-size: inherit;
  padding: 0;
  -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  padding: 0.5rem 0;
  border-radius: 4px;
  max-width: 100%;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
} */
.float-right-2 {
  background-color: #124a2f !important;
  border-color: #ffffff !important;
  color: #fff !important;
}

.container-1 {
  width: 900px;
  margin: auto;
}

.card {
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  background-color: #fff;
  margin-bottom: 20px;
  border-color: #ebedf2;
}

.comboShowBox {
  margin-top: 100px;
  /* border: 1px solid black; */
  padding: 10px;
}

.innerCombo {
  padding: 10px;
  /* border: 1px solid gray; */
  border-radius: 5px;
  overflow: hidden;
  background-color: #e8f1f8;
}

.btncomboSec {
  padding: 10px;
  background-color: lightcyan;
}

.pickvalD {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
}

.picks {
  width: 500px;
  margin: 10px;
  border-radius: 6px;
  background-color: #124a2f;
  color: white;
  padding: 10px;
}

.picks>h6,
h5 {
  /* color: white; */
}

.etgMember {
  display: flex;
  flex-wrap: wrap;
}

.etgMember>div {
  background-color: #00b9ff;
  width: 300px;
  height: 300px;
  margin: 10px;
}

.connectWith ul li a.google {
  background-color: lightcyan;
  font-size: 35px;
}

.sevi {
  /* padding: 20px 20px 0; */
  background-color: rgb(245, 245, 247);
  background-size: cover;
  border-radius: 0;
  border: 1px solid #ededed;
}

.adam_description {
  padding: 20px;
}

.adam_description a {
  color: #000;
}

.sevi-img img {
  max-height: 100%;
  max-width: 100%;
}

.sevi-img {
  flex: 1 1 100%;
  margin: 0 auto;
  justify-content: center;
  display: flex;
}

.sevi--2 {
  height: 288px;
}

.seci-2 {
  height: 360px;
}

.singleProducts .single-link img {
  height: 315px;
}

.singleProducts-2 {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.todayDealsSec-2 {
  background: #f7f7f7;
}

.pd-block p {
  font-weight: 600;
  font-size: 25px;
  line-height: 1;
  color: red;
  text-align: justify;
}

.pd-block .byu-p {
  color: rgba(46, 46, 46, 0.9);
  font-size: 14px;
  line-height: 18px;
}

.featuredInfo-2 {
  width: 300px;
}

.featuredImg-2 a img {
  height: 250px;
}

.featuredImg-2 {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.quickView-2 {
  right: 18px;
}

.timer ul {
  display: flex;
  box-sizing: border-box;
  /* visibility: hidden; */
  margin: 10px 0;
  padding: 10px;
  height: 60px;
  width: 100%;
  max-width: 260px;
  line-height: 1;
  border-radius: 0;
  background: #fff;
}

.timer ul li {
  border-right: 1px solid #eee;
  position: relative;
  flex: 1;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 11px;
  padding: 0 5px;
  color: #7b7b7b;
}

.timer ul li span {
  padding-bottom: 2px;
  font-size: x-large;
  font-weight: bold;
  color: #2e2e2e;
}

@media(max-width: 420px) {
  .singleProducts-2 {
    width: 100% !important;
  }
}

@media(max-width:440px) {
  .rupees .rupees-select {
    display: none;
  }

  .Purchase-sec {
    width: 100% !important;
  }
}

.promotion {
  background: #00a046;
  padding: 20px 0px;
}

.promotion-item {
  background: #ffffff;
  border-radius: 12px;
  /* padding: 0px 10px; */
}

.ab__dotd_promotions-item_days_left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: var(--font-size-small);
  text-align: center;
  padding: 5px 7px;
  margin: 15px 0 15px 15px;
  max-width: 80px;
  min-width: 50px;
  height: 70px;
  background: #f6f6f6;
  color: #7b7b7b;
  border-bottom: 1px solid #e1e1e1;
  box-shadow: 0 1px 1px #f6f6f6, 0 2px 1px #d5d5d5, 0 3px 1px #fbfbfb, 0 4px 1px #d5d5d5;
  border-radius: var(--border-radius);
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #eee;
}

.ab__dotd_promotions-item_days_left::before {
  content: "";
  border-top: 4px dotted #fff;
  width: 100%;
  height: 0;
  position: absolute;
  top: -1px;
}

.ab__dotd_promotions-item_days_left span {
  display: block;
  padding-top: 2px;
  font-size: var(--font-size-big);
  line-height: 90%;
  font-weight: bold;
  color: #2e2e2e;
}

.ab__dotd_promotions-item_title {
  display: inline-flex;
  flex-direction: column;
  flex: 1;
  min-height: 110px;
  padding: 15px;
}

.ab__dotd_promotions-item_title a {
  color: #000;
}

.ab__dotd_promotions-item_date {
  display: block;
  margin-top: 5px;
  font-size: var(--font-size-small);
  color: #7b7b7b;
}

.promotion-item-image a img {
  border-radius: 15px;
}

.param {
  padding: 0px 15px;
}

.optional-btn-2 {
  border: 1px solid white;
  color: #fff;
}

.fisherman-content-2 span {
  color: #fff;
}

.featuredImg-3 {
  display: flex;
  justify-content: center;
}

.featuredImg-3 a img {
  height: 250px;
}

* {
  /* overflow-x: hidden; */
  /* overflow-y: hidden; */

}


.promotion button.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  position: absolute;
  top: 50%;
}

.promotion button.slick-arrow.slick-prev {
  left: 30px;
  z-index: 1;
}

.promotion button.slick-arrow.slick-next {
  right: 30px;
  z-index: 1;
}

.card-body {
  overflow: auto;
}

.card {
  overflow: auto;
}

.aiz-table {
  overflow: hidden;
}

.relatedProductSec-3 .slick-prev,
.slick-next {
  /* display: none !important; */
}

.userNamePro {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  display: block;
  left: -11px;
}

.featuredImg-2 {
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.otp-login button {
  width: 100%;
}

.sevi:hover {
  /* border: 2px dotted red; */
}

.fisherman-content-2 h3 {
  font-size: 20px;
  font-weight: 900;
}

.text_1 {
  color: #4d4d4f;
  font-size: 14px;
  line-height: 140%;
}

.text_2 {
  color: #4d4d4f;
  font-family: Lato Regular;
  font-size: 15px;
  font-weight: 500;
  line-height: 16.8px
}

.price-color {
  color: red;
}

.fisherman-content span {
  font-weight: 600;
}

.freshMeat-2 {
  width: 100% !important;
}

.containt-about {
  position: relative;
  /* padding: 62px 110px 70px 50px; */
}

.containt-about .sec-title {
  /* margin-bottom: 21px; */
}

.text-about p {
  text-align: justify;
  color: #000;
  margin-bottom: 2px;
}

.sec-title span {
  color: #da292a;
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Heebo', sans-serif;
  font-weight: 800;
  margin-bottom: 13px
}

.sec-title h2 {
  position: relative;
  font-size: 30px;
  /* line-height: 57px; */
  color: #353535;
  font-weight: 800;
  padding-bottom: 0px;
}

.text-about {
  margin-bottom: 6px;
}

.list-1 {
  position: relative;
  margin-bottom: 29px;
  padding: 0px;
}

.list-1 li {
  position: relative;
  display: block;
  font-size: 16px;
  font-family: 'Heebo', sans-serif;
  font-weight: 700;
  color: #282932;
  margin-bottom: 10px;
  /* padding-left: 30px; */
}

.list-1 li svg {
  font-size: 20px;
  color: red;
}

.time-slot {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 10px;
}

.head-time h2 {
  font-weight: 700;
  font-size: 20px;
}

.choose-date .date-1 {
  width: 300px;
  margin: 0px 185px;
  height: 30px;
  border: 3px dotted red;
}

.choose-date .date-1:focus-visible {
  border: 3px dotted #000;
}

.morning-slot h2 span {
  font-weight: 700;
  font-size: 20px;
}

.morning-slot h2 svg {
  margin-right: 10px;
  color: yellow;
}

.morning-slot form .time-ss .time-s {
  width: 25px !important;
}

.radioGroupForm div div div div+div {
  width: 20px !important;
  height: 20px !important;
  background-color: red;
  margin-left: 2px;
}

.time-ss>div {
  width: 100px;
  padding: 8px !important;
  margin-right: 2px !important;
}

.radioo {
  width: 100%;
  border: 1px solid black;
  padding: 10px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.radioo input {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.radioo label {
  font-weight: 900 !important;
}

.thunk-page-top-title {
  font-size: 24px;
  line-height: 38px;
}

.page-head .wp-block-heading {
  font-size: 23px;
  line-height: 35px;
  font-weight: 800;
  letter-spacing: 0;
}

.page-head {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 15px;
  margin: 20px 0px;
}

.conatct-msg {
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.conatct-msg-2 {
  margin: auto;
  width: 600px !important;
}

.page-head ul {
  padding: 0px;
}

.page-head ul li svg {
  color: red;
  margin-right: 3px;
}

.page-head ul li {
  transition: ease-in-out;
}

.page-head ul li:hover {
  margin-left: 10px;
  /* transition: all 1s; */
  transition: ease-in-out 0.9;
}

@media(max-width:425px) {
  .choose-date .date-1 {
    width: 100% !important;
    margin: 0px !important;
  }

  .singleProducts-2 .single-link {
    width: 100%;
  }

  .singleProducts-2 .single-link img {
    width: 100%;
  }
}

.jss5 {
  color: #555;
  cursor: pointer;
  height: 40px;
  margin: 0 16px 0;
  display: flex;
  padding: 5px;
  overflow: hidden;
  font-size: 12px;
  align-items: center;
  font-weight: 600 !important;
  line-height: 5;
  white-space: nowrap;
  border-radius: 4px;
  text-overflow: ellipsis;
  justify-content: space-between;
  background-color: #ffffff;
  border-color: black;
}

.modal-dialog-centered {
  width: 500px !important;
  text-align: center;
}

.modal-dialog-centered .modal-content .modal-header .modal-title {
  text-align: center;
  font-weight: 700;
}

.form-check .form-check-input-2 {
  float: none !important;
}

.india-side {
  display: flex;
  justify-content: center;

}

.width-india .form-check {
  display: flex;
  margin-right: 10px;
}

.outsideIndia .form-check {
  display: flex;
}

.width-india .form-check .form-check-input-2 {
  margin-right: 10px;
}

.outsideIndia .form-check .form-check-input-2 {
  margin-right: 10px;
}

.location-pup {
  display: grid;
}

.location-pup input {
  box-shadow: 0 0 10px #dd9838;
  border: 1px solid #dd9838;
  color: #222;
  width: 100%;
  margin: 10px 0px;
  font-size: 16px;
  border-radius: 4px;
  height: 44px;
}

.location-pup .conti {
  background-color: #dd9838;
  width: 100%;
  /* padding: 14px 0px 12px; */
  margin: 20px 0px;
  font-size: 18px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  height: 44px;
  border: none;
}

.out-side-india {
  display: grid;
}

.out-side-india .form-select {
  height: 44px;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize;
  background: #f2f2f2;
  border-radius: 4px;
  border: 1px solid #cacaca;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, .15);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, .15);
  margin: 10px 0px;
}

.out-side-india input {
  min-height: 40px;
  /* padding: 0 30px; */
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 10px;
  height: 44px;
  border: 1px solid #cacaca;
}

.out-side-india .conti {
  background-color: rgb(173 15 0);
  width: 100%;
  /* padding: 14px 0px 12px; */
  /* margin-: 10x; */
  font-size: 18px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  height: 44px;
  border: none;
}

.classSet .slick-initialized .slick-list .slick-track {
  width: 100% !important;
}

.quickView .addProduct button svg {
  color: #fff !important;
}

.bannerSection .slick-initialized .slick-prev::before {
  font-size: 30px !important;
  color: #000 !important;
}

.bannerSection .slick-initialized .slick-next::before {
  font-size: 30px !important;
  color: #000 !important;
}

.bannerSection .slick-slider.slick-initialized .slick-prev {
  left: 70px !important;
  z-index: 9 !important;
}

.bannerSection .slick-slider.slick-initialized .slick-next {
  right: 70px !important;
  z-index: 9 !important;
}

.socialMedia ul li .logoutBtn svg {
  font-size: 16px;
}

.category {
  position: relative;
}

.category .cate svg {
  margin-right: 10px;
  font-size: 20px;
}

.CATEGORYIE {
  box-shadow: 0 2px 12px rgba(0, 0, 0, .25);
  position: absolute;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  width: 38.4vw;
  /* height: 39vw; */
  top: 43px;
  left: -11vw;
  color: #4b4f54;
  z-index: 1;
  display: none;
}

.CATEGORYIE:before {
  content: "";
  position: absolute;
  border: 16px solid transparent;
  border-bottom-color: #fff;
  top: -32px;
  left: 11vw;
}

.CATEGORYIE .CategoryMenu_category_list {
  display: inline-grid;
  /* overflow: scroll; */
  /* grid-template-rows: repeat(auto-fit, 55px); */
  padding: 0px;
  width: 100%;
}

.CategoryMenu_category_list .category-menu {
  /* display: inline-flex; */
  align-items: center;
  border-bottom: 1px solid #f4f5ff;
  font-family: Lato Regular, sans-serif;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.category-menu-img {
  margin: 12px 16px 8px 12px;
}

.category-menu-img .span-image {
  box-sizing: border-box;
  /* display: inline-block; */
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}

.CategoryMenu_category_list .category-menu .sub-menu-cat {
  position: absolute;
  background-color: #f1f1f1;
  padding: 10px 16px;
  /* grid-template-rows: repeat(auto-fit, 56px); */
  /* height: 100%; */
  right: 0px;
  top: -44px;
  width: 49% !important;
  display: none;
}

.sub-menu-cat li {
  /* display: inline-flex; */
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  padding: 16px 24px;
  /* width: 100%; */
  cursor: pointer;
}

.sub-menu-cat li a {
  color: #000;
}

.searchItem .category:hover .CATEGORYIE {
  display: block;
}

.CATEGORYIE .CategoryMenu_category_list .category-menu:hover .sub-menu-cat {
  display: block;
}

.aboutCompanyText h3 {
  font-weight: 700;
}

.CATEGORYIE .CategoryMenu_category_list .category-menu:hover {
  background-color: #f1f1f1;
}

.category-menu-img .span-image img {
  height: 50px;
}

.app-option .footer-heading img {
  width: 135px;
  margin-top: 10px;
  margin-right: 5px;
}

.timer-2 ul {
  display: flex;
  box-sizing: border-box;
  /* visibility: hidden; */
  margin: 0px;
  /* padding: 10px; */
  height: 30px;
  width: 100%;
  max-width: 260px;
  line-height: 1;
  border-radius: 0;
  background: none;
}

.timer-2 ul li {
  flex-direction: inherit;
  color: #fff;
}

.timer-2 ul li span {
  color: #fff;
}

.timer-2 {
  display: flex;
  align-items: center;
}

.timer-2 p {
  margin-left: 10px;
}

.category-menu .category-menu-img a {
  color: #000 !important;
}

.timer-2 {
  width: 400px;
}

.bannerContent .tm-heroslider-content .slider {
  transform: translateX(0%);
}

.bannerContent .tm-heroslider-content {
  transition: all 1s ease-in-out .5s;
}

.tm-heroslider-content h1 {
  margin-top: -10px;
  font-size: 50px;
  line-height: 64px;
  color: #232b2b;
}

.tm-heroslider-content h1 {
  margin-top: -10px;
  font-size: 50px;
  line-height: 64px;
  font-weight: 700;
  color: #232b2b;
}

.tm-heroslider-content p {
  font-size: 20px;
  line-height: 30px;
  margin-top: 30px;
  color: #232b2b;
}

.tm-heroslider-content a {
  display: inline-block;
  height: 50px;
  min-width: 150px;
  background: #dd9838;
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  border-radius: 4px;
  z-index: 1;
  position: relative;
  overflow: hidden;
  padding: 0 40px;
  vertical-align: middle;
  font-size: 16px;
}

.copyright-area {
  border-top: 1px solid #ebebeb;
}

.tm-feature-area {
  padding-top: 50px;
  padding-bottom: 50px;
}

.tm-section {
  position: relative;
  z-index: 1;
}

.bg-grey {
  background-color: #f7f7f7;
}

.mt-30-reverse {
  margin-top: -30px;
}

.tm-feature {
  display: flex;
}

.tm-feature-icon {
  flex: 0 0 100px;
  max-width: 100px;
  text-align: center;
  align-self: center;
}

.tm-feature-content h6 {
  font-weight: 700;
  margin-bottom: 5px;
}

.tm-offer-content h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.tm-offer-content h1 {
  font-size: 60px;
  line-height: 72px;
  font-weight: 700;
}

.tm-offer-content h1 span {
  color: #dd9838;
}

.tm-countdown-pack:not(:last-child) {
  margin-right: 30px;
}

.tm-countdown-pack {
  min-width: 100px;
  min-height: 100px;
  text-align: center;
  background: #fff;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  box-shadow: 0 2px 10px rgba(34, 34, 34, .15);
  position: relative;
}

.tm-button,
a.tm-button,
button.tm-button {
  display: inline-block;
  height: 50px;
  min-width: 150px;
  background: #dd9838;
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  border-radius: 4px;
  z-index: 1;
  position: relative;
  overflow: hidden;
  padding: 0 40px;
  vertical-align: middle;
  font-size: 16px;
  margin-top: 50px;
}

.order-1 {
  order: 1 !important;
}

.tm-offer-image {
  height: 450px;
  width: 450px;
  padding: 20px;
  border-radius: 1000px;
  margin: auto;
  position: relative;
}

.tm-offer-image a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(242, 186, 89, .15);
  z-index: 0;
  border-radius: 1000px;
  -webkit-animation: 5s ease-in-out .2s infinite both;
  animation: 5s ease-in-out .2s infinite both;
  /* animation: ; */
}

.tm-offer-image a img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  max-width: 90%;
}

.tm-offer-image a:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(242, 186, 89, .1);
  z-index: 0;
  border-radius: 1000px;
  -webkit-animation: 5s ease-in-out .2s infinite both;
  animation: 5s ease-in-out .2s infinite both;
}

.tm-section {
  position: relative;
  z-index: 1;
}

.mt-30-reverse {
  margin-top: -30px;
}

a:visited {
  color: inherit;
}

.tm-banner {
  position: relative;
  display: block;
  overflow: hidden;
}

.tm-banner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(242, 186, 89, .3);
  transition: all .4s ease-in-out;
  visibility: hidden;
  opacity: 0;
  /* z-index: 1; */
}

.tm-banner {
  position: relative;
  display: block;
  overflow: hidden;
}

.tm-banner:hover::before {
  visibility: visible;
  opacity: 1;
  animation: bannerAnim 1s linear 0s 1 both;
}

.itg-hover-section {
  position: relative;
}

figure {
  margin: 0;
}

.itg-hover-section:before {
  background-color: #f97242;
}

.itg-hover-section:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  opacity: 0;
}

.itg-hover-section:before {
  left: 0;
  right: 0;
  -webkit-transition: all .45s ease-in-out;
  /* -moz-transition: all .45s ease-in-out;
  -o-transition: all .45s ease-in-out;
  -ms-transition: all .45s ease-in-out; */
}

figcaption.categorie-boxs {
  position: absolute;
  top: 50%;
  text-align: center;
  opacity: 0;
  margin-top: -38px;
}

figcaption.categorie-boxs,
figure.itg-hover-section:before {
  left: 0;
  right: 0;
  -webkit-transition: all .45s ease-in-out;
  /* -moz-transition: all .45s ease-in-out;
  -o-transition: all .45s ease-in-out;
  -ms-transition: all .45s ease-in-out; */
}

.categoryBody {
  position: relative;
}

.categoryHeader {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #ffa90fc4;
  transition: .4s ease-in-out;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.categoryBody:hover .categoryHeader {
  opacity: 1;
}